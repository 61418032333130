<template>
  <div class="signup-form">
    <ng-fieldset
      ref="form"
      :fields="formFields"
      v-model="formModel"
    ></ng-fieldset>
    <div class="signup-form__actions d-sm-flex align-center">
      <div>
        <ng-button
          class="mr-1"
          ref="submitButton"
          type="submit"
          color="primary"
          large
          @click.prevent="submit()"
        >
          {{ $t('signup') }} </ng-button
        ><span class="d-none d-sm-inline">
          {{ $t('or') }}&nbsp;<a class="d-inline-block" href="/login/">{{
            $t('signin')
          }}</a></span
        >
      </div>
    </div>
  </div>
</template>

<i18n>
  {
    "en": {
      "signin": "Sign in",
      "signup": "Create account",
      "or": "or"
    },
    "ru": {
      "signin": "Войти",
      "signup": "Создать аккаунт",
      "or": "или"
    }
  }
</i18n>

<script>
import { validationMixin } from 'vuelidate';
import vuelidatable from '@ngservices_front/mixins/vuelidatable';
import { required, email, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'SignupForm',
  mixins: [validationMixin, vuelidatable],
  props: {
    formElId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formFields: null,
      formModel: null,
    };
  },
  validations: {
    formModel: {
      email: { required, email },
      password: { required, minLength: minLength(8) },
      agree: { required },
    },
  },
  computed: {
    formEl() {
      return document.getElementById(this.formElId);
    },
  },
  watch: {
    vuelidateErrors: {
      deep: true,
      handler() {
        this.setErrorsTo(this.formFields, { validationRoot: 'formModel' });
      },
    },
  },
  created() {
    this.formFields = window.signupFormFields;
    this.formModel = window.signupFormModel;
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.formEl.submit();
      } else {
        this.$vuetify.goTo(this.formEl, { offset: 100 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-form {
  &__actions {
    font-size: 14px;
  }
}
</style>
