// export function htmlToArray(el) {
//   var arr = [];
//   var arrayList = el.children();
//   arrayList.each(function () {
//     arr.push($(this).text().toLowerCase());
//   });
//   return arr;
// }

export function getURLParameter(name) {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
        location.search,
      ) || [null, ''])[1].replace(/\+/g, '%20'),
    ) || null
  );
}

//Get query string parameters

export function get_query_value(name, url) {
  if (!url) url = location.href;
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');

  var regexS_attr = '[\\?&]' + name,
    regexS_value = '[\\?&]' + name + '=([^&#]*)',
    regex_attr = new RegExp(regexS_attr),
    regex_value = new RegExp(regexS_value),
    results_attr = regex_attr.exec(url),
    results_value = regex_value.exec(url);

  if (results_attr && !results_value) {
    return '';
  } else {
    return results_value == null ? null : results_value[1];
  }
}

// Remove get parameters
export function removeURLParameters(url) {
  var urlparts = url.split('?');
  return urlparts[0];
}

export function removeURLParameter(parameter, url) {
  if (!url) url = location.href;
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(f, ms) {
  let timer = null;

  return function (...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(onComplete, ms);
  };
}

// search in nested object by [key, value]
// result contains found object and path to it

export const deepSearchWithPath = (
  array,
  [key, value],
  childrenKey = 'children',
  depth = 0,
  path = [],
) => {
  return array.reduce(
    (acc, item) => {
      if (acc.value) return acc;
      if (depth === 0) acc.path = []; //reset path for top level item

      acc.path.push(item);

      if (item[key] === value) return { value: item, path: acc.path };

      if (childrenKey in item) {
        const nextDepth = depth + 1;
        return deepSearchWithPath(
          item.children,
          [key, value],
          'children',
          nextDepth,
          acc.path,
        );
      }
      acc.path.pop();

      return { value: null, path: acc.path };
    },
    { value: null, path },
  );
};

export const formatNumber = (num) =>
  num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1 ');

export const capitalize = (value) => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};
