import Vue from 'vue';
import NgLabel from './components/ui/NgLabel';
import NgTextField from './components/ui/NgTextField';
import NgTextArea from './components/ui/NgTextArea';
import NgPasswordTextField from './components/ui/NgPasswordTextField';
import NgSelect from './components/ui/NgSelect';
import NgAutocomplete from './components/ui/NgAutocomplete';
import NgCheckbox from './components/ui/NgCheckbox/NgCheckbox';
import NgDatePicker from './components/ui/NgDatePicker';
import NgCardGroup from './components/ui/NgCardGroup';
import NgFieldset from './components/ui/NgFieldset/NgFieldset';

Vue.component('ng-label', NgLabel);
Vue.component('ng-text-field', NgTextField);
Vue.component('ng-text-area', NgTextArea);
Vue.component('ng-password-text-field', NgPasswordTextField);
Vue.component('ng-select', NgSelect);
Vue.component('ng-autocomplete', NgAutocomplete);
Vue.component('ng-checkbox', NgCheckbox);
Vue.component('ng-date-picker', NgDatePicker);
Vue.component('ng-card-group', NgCardGroup);
Vue.component('ng-fieldset', NgFieldset);
Vue.component('ng-phone-input', () =>
  import(/* webpackPrefetch: true */ './components/ui/NgPhoneInput'),
);
