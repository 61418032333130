<template>
  <div class="profile-management">
    <h3>{{ $t('title') }}</h3>
    <v-row>
      <v-col v-for="item in items" :key="item.key" cols="12" sm="6">
        <v-card class="pa-1 light-secondary">
          <v-card-title>
            <h4 class="mb-0">{{ $t(`${item.key}.title`) }}</h4>
          </v-card-title>
          <v-card-text>
            <div class="body-1">{{ $t(`${item.key}.description`) }}</div>
          </v-card-text>
          <v-card-actions class="pt-0">
            <a
              v-if="item.key === 'removal'"
              type="button"
              class="v-btn btn-tool noshadow error--text"
              @click.prevent="showDeleteConfirmation"
            >
              {{ $t(`${item.key}.buttonText`) }}
            </a>
            <a
              v-else
              :href="item.url"
              type="button"
              class="v-btn btn-tool noshadow"
              :class="item.buttonClass"
            >
              {{ $t(`${item.key}.buttonText`) }}
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<i18n>
  {
    "en": {
      "title": "Profile management",
      "export": {
        "title": "Data export",
        "description": "You will receive a copy of your personal data processed by NextGIS.",
        "buttonText": "Request data"
      },
      "removal": {
        "title": "Profile removal",
        "description": "Your profile and personal data will be deleted.",
        "buttonText": "Delete profile",
        "confirmationText": "Are you sure you want to delete profile?"
      }
    },
    "ru": {
      "title": "Управление профилем",
      "export": {
        "title": "Экспорт данных",
        "description": "Вы получите копию ваших персональных данных, обрабатываемых NextGIS.",
        "buttonText": "Запросить данные"
      },
      "removal": {
        "title": "Удаление профиля",
        "description": "Ваш профиль и персональные данные будут удалены.",
        "buttonText": "Удалить профиль"
      }
    }
  }
</i18n>

<script>
export default {
  name: 'ProfileManagement',
  data() {
    return {
      items: [
        {
          key: 'export',
          url: '/export_profile',
        },
        {
          key: 'removal',
          url: '/remove',
        },
      ],
    };
  },
  methods: {
    showDeleteConfirmation() {
      if (confirm('Are you sure you want to delete profile?')) {
        window.location = this.items.find((item) => item.key === 'removal').url;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
