export default {
  getUserData() {
    return new Promise(function (resolve) {
      let result = {
        ...window.userInfo,
        plan: window.user && window.user.plan,
        webgisUrl: window.user && window.user.webgisUrl,
        company: window.user && window.user.company,
        industry: window.user && window.user.industry,
        phone: window.user && window.user.phone,
        email: window.user && window.user.email,
        timezone: window.user && window.user.timezone,
      };
      resolve(result);
    });
  },
};
