const payments = {
  namespaced: true,
  state: () => ({ isWaitingDataFromPaymentGateway: false }),
  mutations: {
    SET_IS_WAITING_DATA_FROM_PAYMENT_GATEWAY(state, isWaitingData) {
      state.isWaitingDataFromPaymentGateway = isWaitingData;
    },
  },
};

export default payments;
