<template>
  <ngid-base-layout class="subscription-page" fluid>
    <template v-if="isDataLoaded">
      <h1>{{ $t('subscription.title') }}</h1>
      <subscription-info v-if="plan" />
      <slot></slot>
      <SubscriptionDialog
        :value="isAlertShown"
        @close="hideAlert"
        :mode="subscriptionAlertType"
        :plan="plan"
        :price="price"
        :currency="currency"
        :expire-at="expireAt"
      />
    </template>
    <v-row v-else>
      <v-col>
        <ng-sheet class="d-inline-flex align-center" :outlined="isWaitingDataFromPaymentGateway">
          <v-progress-circular indeterminate color="primary" class="mr-4"></v-progress-circular>
          <span v-if="isWaitingDataFromPaymentGateway">
            {{ $t('subscription.waitingResponseFromPaymentSystem') }}
          </span>
        </ng-sheet>
      </v-col>
    </v-row>
  </ngid-base-layout>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import NgidBaseLayout from '@/layouts/NgidBaseLayout';
import SubscriptionInfo from '@/components/subscription/SubscriptionInfo';
import SubscriptionDialog from '@/components/subscription/SubscriptionDialog.vue';

export default {
  components: {
    NgidBaseLayout,
    SubscriptionInfo,
    SubscriptionDialog,
  },
  computed: {
    ...mapState('subscription', [
      'isDataLoaded',
      'plan',
      'price',
      'currency',
      'autopayment',
      'expireAt',
      'canceledAt',
      'interval',
      'subscriptionAlertType',
    ]),
    ...mapState('payments', ['isWaitingDataFromPaymentGateway']),
    isAlertShown() {
      return !!this.subscriptionAlertType;
    },
  },
  methods: {
    ...mapMutations('subscription', ['SET_SUBSCRIPTION_ALERT_TYPE']),
    hideAlert() {
      this.SET_SUBSCRIPTION_ALERT_TYPE(null);
    },
  },
};
</script>

<style lang="scss" scoped></style>
