const myUrl = 'https://my.nextgis.com';

const urls = {
  nextgis_site: {
    en: 'https://nextgis.com',
    ru: 'https://nextgis.ru',
  },
  nextgis_terms: {
    en: 'https://nextgis.com/terms',
    ru: 'https://nextgis.ru/terms',
  },
  nextgis_privacy: {
    en: 'https://nextgis.com/privacy',
    ru: 'https://nextgis.ru/privacy',
  },
  my: {
    en: myUrl,
    ru: myUrl,
  },
  login: {
    en: '/login',
    ru: '/login',
  },
  signup: {
    en: '/signup',
    ru: '/signup',
  },
  logout: {
    en: '/logout',
    ru: '/logout',
  },
  ngid_profile: {
    en: `${myUrl}/profile`,
    ru: `${myUrl}/profile`,
  },
  nextgis_connect_docs: {
    en: 'https://docs.nextgis.com/docs_ngcom/source/ngqgis_connect.html',
    ru: 'https://docs.nextgis.ru/docs_ngcom/source/ngqgis_connect.html',
  },
  webgis_docs: {
    en: 'https://docs.nextgis.com/docs_ngcom/source/toc.html',
    ru: 'https://docs.nextgis.ru/docs_ngcom/source/toc.html',
  },
};

export function getUrlByLocale(name, locale) {
  const langKey = locale === 'ru' ? 'ru' : 'en';
  return urls[name] && urls[name].en
    ? urls[name][langKey]
      ? urls[name][langKey]
      : urls[name].en
    : '#';
}
