<template>
  <div class="webgis-related-info">
    <template v-if="isCompact">
      <ng-sheet outlined color="secondary">
        <h4 class="mb-3" v-html="$t('webgis.connectTitle')"></h4>
        <p>
          <small v-html="$t('webgis.connectDescription', { url: connectUrl })"></small>
        </p>
        <iframe
          width="100%"
          height="auto"
          style="aspect-ratio: 16 / 9"
          :src="connectVideoUrl"
          :title="$t('webgis.connectTitle')"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </ng-sheet>
    </template>
    <template v-else>
      <h2>{{ $t('webgis.easyStart') }}</h2>
      <v-row>
        <v-col cols="12" sm="7">
          <ng-sheet outlined color="secondary">
            <h4 class="mb-3" v-html="$t('webgis.connectTitle')"></h4>
            <p>
              <small v-html="$t('webgis.connectDescription', { url: connectUrl })"></small>
            </p>
            <iframe
              width="100%"
              height="auto"
              style="aspect-ratio: 16 / 9"
              :src="connectVideoUrl"
              :title="$t('webgis.connectTitle')"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </ng-sheet>
        </v-col>
        <v-col cols="12" sm="5">
          <a href="#" onclick="Chatra('openChat', true); return false;">
            <v-hover v-slot="{ hover }">
              <ng-sheet
                outlined
                :outlined-bg="hover ? $vuetify.theme.currentTheme.primary : ''"
                :color="hover ? 'primary' : ''"
                :dark="hover"
              >
                <h4 class="mb-2">
                  <v-icon class="mt-n1 mr-1" :color="hover ? 'white' : 'primary'">
                    mdi-star-face
                  </v-icon>
                  {{ $t('webgis.demoTitle') }}
                  <v-icon class="ng-sheet__external-icon mt-n1"> mdi-arrow-top-right-thin </v-icon>
                </h4>
                <p>
                  <small v-html="$t('webgis.demoDescription')"></small>
                </p> </ng-sheet
            ></v-hover>
          </a>
          <a :href="webgisDocsUrl" target="_blank">
            <v-hover v-slot="{ hover }">
              <ng-sheet
                class="mt-6"
                outlined
                :outlined-bg="hover ? $vuetify.theme.currentTheme.primary : ''"
                :color="hover ? 'primary' : ''"
                :dark="hover"
              >
                <h4 class="mb-2">
                  <v-icon class="mt-n1 mr-1" :color="hover ? 'white' : 'primary'">
                    mdi-file-document-multiple
                  </v-icon>
                  {{ $t('webgis.docTitle') }}
                  <v-icon class="ng-sheet__external-icon mt-n1"> mdi-arrow-top-right-thin </v-icon>
                </h4>
                <p>
                  <small v-html="$t('webgis.docDescription')"></small>
                </p> </ng-sheet
            ></v-hover>
          </a>
          <a
            @click="sendCounterEvent"
            :href="`https://data.nextgis.com/${$i18n.locale}/?utm_source=nextgis&utm_medium=referral&utm_campaign=mywebgis_data`"
            target="_blank"
          >
            <v-hover v-slot="{ hover }">
              <ng-sheet
                class="mt-6"
                outlined
                :outlined-bg="hover ? $vuetify.theme.currentTheme.primary : ''"
                :color="hover ? 'primary' : ''"
                :dark="hover"
              >
                <h4 class="mb-2">
                  <v-icon class="mt-n1 mr-1" :color="hover ? 'white' : 'primary'">
                    mdi-vector-polygon
                  </v-icon>
                  {{ $t('webgis.dataTitle') }}
                  <v-icon class="ng-sheet__external-icon mt-n1"> mdi-arrow-top-right-thin </v-icon>
                </h4>
                <p>
                  <small v-html="$t('webgis.dataDescription')"></small>
                </p> </ng-sheet
            ></v-hover>
          </a>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { getUrlByLocale } from '@ngservices_front/services/UrlService';
import { sendCounterEvent } from '@/services/counterService';

export default {
  name: 'WebgisRelatedInfo',
  props: {
    isCompact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    connectUrl() {
      return getUrlByLocale('nextgis_connect_docs', this.$i18n.locale);
    },
    connectVideoUrl() {
      return this.$i18n.locale === 'ru'
        ? 'https://www.youtube.com/embed/qIByQEqZ4oQ'
        : 'https://www.youtube.com/embed/Wwx1mowUAL4';
    },
    webgisDocsUrl() {
      return getUrlByLocale('webgis_docs', this.$i18n.locale);
    },
  },
  methods: {
    sendCounterEvent() {
      sendCounterEvent({ name: 'go-to-data' });
    },
  },
};
</script>

<style lang="scss" scoped></style>
