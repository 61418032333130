<template>
  <div class="app-menu" :class="`app-menu--${view}`">
    <slot name="prepend-menu"> </slot>
    <template v-for="item in items">
      <router-link
        v-if="item.to"
        :key="item.id"
        :to="item.to"
        v-slot="{ href, navigate, isActive }"
      >
        <AppMenuItem
          :is-active="isActive"
          :icon="item.icon"
          @click.native="navigate"
          :href="href"
          :view="view"
        >
          <span v-html="item.text"></span>
        </AppMenuItem>
      </router-link>
      <AppMenuItem
        v-else
        :key="item.id"
        :is-active="activePathIds.includes(item.id)"
        :icon="item.icon"
        :href="item.link"
        :view="view"
      >
        <span v-html="item.text"></span>
      </AppMenuItem>
    </template>
    <slot name="append-menu"> </slot>
  </div>
</template>

<script>
import { deepSearchWithPath } from '../../js/utilities';
import AppMenuItem from './AppMenuItem.vue';

export default {
  name: 'AppMenu',
  components: { AppMenuItem },
  props: {
    items: Array,
    activeItem: String,
    view: {
      type: String, // 'vertical', 'horizontal'
      default: 'horizontal',
    },
  },
  data() {
    return {};
  },
  computed: {
    activePath() {
      return deepSearchWithPath(this.items, ['id', this.activeItem]).path;
    },
    activePathIds() {
      return this.activePath.map((item) => item.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-family: $heading-font-family;
  font-size: 14px;

  &--horizontal {
    display: flex;
  }
}
</style>
