import { notice } from '@nextgis/vuetify-notice';

const NOTIFICATION_DURATION = 10000;

const notifyError = (message) => {
  notice(message, {
    color: 'error',
    timeout: NOTIFICATION_DURATION,
  });
};

export { notifyError };
