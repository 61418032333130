<template>
  <div class="text-center" v-if="!isSubscriptionDataLoaded">
    <v-progress-circular indeterminate color="primary" />
  </div>
  <div v-else>
    <div class="d-flex align-center mb-6">
      <h1 class="mb-0">
        {{ $t('team.title') }}
      </h1>
      <v-chip class="ml-3 mt-2" v-if="teamSize > 0" label color="#e5eef7">
        <span class="primary--text">{{ $t('team.title_team_1', [users.length, teamSize]) }}</span>
      </v-chip>
    </div>
    <v-row>
      <v-col cols="12" lg="8">
        <template v-if="plan === 'premium'">
          <v-layout align-center class="mb-4">
            <ng-text-field
              class="flex-grow-1"
              v-model="search"
              append-icon="mdi-magnify"
              :placeholder="$t('team.search')"
              hide-details
              filled
              full-width
            ></ng-text-field>
            <v-spacer></v-spacer>
            <ng-button
              iconName="mdi-account-plus"
              iconPosition="left"
              color="primary"
              @click="showAddUserDialog"
            >
              {{ $t('team.add') }}
            </ng-button>
          </v-layout>

          <v-alert :value="table_alert" type="error" transition="scale-transition">
            {{ table_alert_message }}
          </v-alert>

          <v-dialog v-model="dialogTeamSizeAlert" max-width="380px">
            <v-card>
              <div class="pa-4">
                <h3>
                  {{ $tc('team.dialog_users_alert.title', teamSize) }}
                </h3>
                <p
                  v-html="
                    $tc('team.dialog_users_alert.msg', teamSize) +
                    ' ' +
                    $t('team.dialog_users_alert.msg_2')
                  "
                ></p>
                <ng-button
                  class="mt-6"
                  color="primary"
                  @click="dialogTeamSizeAlert = false"
                  block
                  >{{ $t('app.gotIt') }}</ng-button
                >
              </div>
            </v-card>
          </v-dialog>
          <v-data-table
            :headers="headers"
            :items="users"
            :options="tableOptions"
            :loading="loading"
            :search="search"
            class="team-table elevation-0"
          >
            <template v-slot:progress>
              <v-progress-linear color="blue" indeterminate></v-progress-linear>
            </template>

            <template v-slot:item="{ item }">
              <tr :class="item.owner ? 'grey lighten-3' : ''">
                <td>{{ item.username }}</td>
                <td class="text-right">
                  <v-icon small v-if="!item.owner" @click="deleteUserFormTeam(item)">
                    delete
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-dialog v-model="dialog" max-width="500px" v-on:scroll.passive="onScroll">
            <v-card>
              <v-card-title>
                <h3 class="mb-0 mt-2">{{ $t('team.adduser') }}</h3>
              </v-card-title>

              <v-card-text>
                <v-autocomplete
                  v-model="userToAdd"
                  :label="$t('team.login')"
                  :items="usersFound"
                  item-text="username"
                  item-value="nextgis_guid"
                  :search-input.sync="searchUsers"
                  :loading="usersSearchLoading"
                  :no-data-text="getNoDataText"
                  filled
                  append-icon="mdi-magnify"
                ></v-autocomplete>
                <v-layout>
                  <v-spacer />
                  <ng-button text @click="close" class="mr-2">{{ $t('app.cancel') }}</ng-button>
                  <ng-button color="primary" @click="save">{{ $t('team.add') }}</ng-button>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template v-else>
          <ng-sheet color="light-secondary" v-html="$t('team.teamIsNotAvailable')"> </ng-sheet>
        </template>
      </v-col>
      <v-col cols="12" lg="4">
        <ng-sheet
          v-if="teamSize && users.length === teamSize"
          outlined
          class="ml-xl-16 d-flex align-start"
        >
          <v-icon class="ml-n1 mr-2">mdi-alert-decagram-outline</v-icon>
          <div>
            {{ $t('team.limitMessage') }} <a href="mailto:sales@nextgis.com">sales@nextgis.com</a>.
          </div>
        </ng-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as teamApi from '@/api/teamApi';
import { fireError } from '@ngservices_front/services/errorService';

import { mapState } from 'vuex';
export default {
  name: 'NgTeam',
  data() {
    return {
      headers: [
        { text: this.$t('team.login'), value: 'username' },
        { text: '', value: 'action', sortable: false },
      ],
      team: undefined,
      tableOptions: {
        sortBy: ['username'],
      },
      loading: false,
      search: '',
      dialogTeamSizeAlert: false,
      dialog: false,
      valid: true,
      searchUsers: null,
      usersSearchLoading: false,
      usersSearchText: null,
      usersFound: [],
      userToAdd: null,
      timerId: null,
      table_alert: false,
      table_alert_message: null,
    };
  },
  computed: {
    ...mapState('subscription', { plan: 'plan', isSubscriptionDataLoaded: 'isDataLoaded' }),
    getNoDataText() {
      if (this.usersSearchText)
        if (this.usersSearchText.length < 3) return this.$t('team.searching.not_enough');
        else return this.$t('team.searching.not_found');
      return this.$t('team.searching.start_typing');
    },
    users() {
      return this.team
        ? [
            {
              username: this.team['owner'] + ' (' + this.$t('team.you') + ')',
              owner: true,
            },
            ...this.team.users,
          ]
        : [];
    },
    teamSize() {
      return this.team ? this.team['max_size'] + 1 : 0; // because owner must be inside
    },
  },
  watch: {
    searchUsers(usernameStart) {
      // Items have already been requested
      if (this.usersSearchLoading) return;
      if (!usernameStart) return;

      this.usersSearchText = usernameStart;

      if (usernameStart.length > 2) {
        this.searchUsersWithDelay(usernameStart);
      }
    },
    isSubscriptionDataLoaded: {
      async handler(value) {
        if (value && this.plan === 'premium') {
          try {
            this.loading = true;
            this.team = await teamApi.getTeam();
          } catch (e) {
            fireError(e);
          } finally {
            this.loading = false;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    showAddUserDialog() {
      if (this.users.length < this.teamSize) {
        this.usersFound = [];
        this.dialog = true;
      } else this.dialogTeamSizeAlert = true;
    },
    close() {
      this.dialog = false;
    },
    async save() {
      this.table_alert = false;
      const usersWithoutOwner = this.users.slice(1);
      try {
        const newTeamUsers = await teamApi.updateUsers([
          ...usersWithoutOwner,
          { nextgis_guid: this.userToAdd },
        ]);
        this.team = newTeamUsers;
        this.close();
      } catch (e) {
        fireError(e);
      }
    },

    async deleteUserFormTeam(userToRemove) {
      this.table_alert = false;
      if (!confirm(this.$t('team.deleteUserAlert'))) return;
      const newUsers = this.users.filter(
        (user) => !user.owner && user.nextgis_guid !== userToRemove.nextgis_guid,
      );
      try {
        const newTeamUsers = await teamApi.updateUsers(newUsers);
        this.team = newTeamUsers;
        this.close();
      } catch (e) {
        fireError(e);
      }
    },
    searchUsersWithDelay(usernameStart) {
      clearTimeout(this.timerId);

      // delay new call 500ms
      this.timerId = setTimeout(async () => {
        this.usersSearchLoading = true;
        try {
          this.usersFound = await teamApi.searchUser(usernameStart);
        } catch (e) {
          fireError(e);
        } finally {
          this.usersSearchLoading = false;
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.team-table {
  border: 1px solid $border-color;
}
</style>
