<template>
  <base-layout fluid contentVCentered>
    <div class="error-block">
      <div class="huge-text error-block__number">{{ code }}</div>
      <h2 class="mt-4 mb-2">{{ title }}</h2>
      <div class="error-block__descr" v-html="errorMessage"></div>
      <ng-button class="mt-6" @click="back()" color="primary" x-large>{{
        $t('back')
      }}</ng-button>
    </div>
  </base-layout>
</template>

<i18n>
  {
    "en": {
      "error": "Error",
      "back": "Go back",
      "title403": "The access to this page is forbidden",
      "message403": "Think it's a mistake? <a id='error-report-link' href='mailto:info@nextgis.com?subject=Denial of access to nextgis.com&body=There is no access to the page'>Let us know</a>.",
      "title400": "Bad request",
      "message400": "Think it's a mistake? <a id='error-report-link' href='mailto:info@nextgis.com?subject=Denial of access to nextgis.com&body=There is no access to the page'>Let us know</a>.",
      "title404": "Page does not exist",
      "message404": "Check your link or <a id='error-report-link' href='mailto:info@nextgis.com?subject=Non-existent page on nextgis.com&body=Action on the website led me to a 404 page'>let us know</a>.",
      "title500": "An error occurred on the server",
      "message500": "Chances are we already working to fix the problem. Try to refresh the page after&nbsp;some time or <a id='error-report-link' href='mailto:info@nextgis.com?subject=Server error on nextgis.com&body=Server error when accessing'>let us know</a>."
    },
    "ru": {
      "error": "Ошибка",
      "back": "Вернуться назад",
      "title403": "Доступ к этой странице запрещен",
      "message403": "Считаете, что это ошибка? <a id='error-report-link' href='mailto:info@nextgis.com?subject=Denial of access to nextgis.com&body=There is no access to the page'>Дайте нам знать</a>.",
      "title400": "Неверный запрос",
      "message400": "Считаете, что это ошибка? <a id='error-report-link' href='mailto:info@nextgis.com?subject=Denial of access to nextgis.com&body=There is no access to the page'>Дайте нам знать</a>.",
      "title404": "Страница не существует",
      "message404": "Проверьте ссылку или <a id='error-report-link' href='mailto:info@nextgis.com?subject=Non-existent page on nextgis.com&body=Action on the website led me to a 404 page'>дайте нам знать</a>.",
      "title500": "Произошла ошибка на сервере",
      "message500": "Скорее всего мы уже работаем над устранением проблемы. Попробуйте обновить страницу через некоторое время или <a id='error-report-link' href='mailto:info@nextgis.com?subject=Server error on nextgis.com&body=Server error when accessing'>дайте нам знать</a>."
    }
  }
</i18n>

<script>
import BaseLayout from '../layouts/BaseLayout.vue';

export default {
  name: 'ErrorPage',
  components: { BaseLayout },
  props: {
    code: {
      type: String,
      default: '404',
    },
  },
  metaInfo() {
    return {
      title: `${this.$t('error')} ${this.code}`,
    };
  },
  computed: {
    title() {
      return this.$te(`title${this.code}`)
        ? this.$t(`title${this.code}`)
        : this.$t('title404');
    },
    errorMessage() {
      return this.$te(`message${this.code}`)
        ? this.$t(`message${this.code}`)
        : this.$t('message404');
    },
  },
  methods: {
    back() {
      history.back();
    },
  },
  mounted() {
    const reportLinkEl = document.getElementById('error-report-link');
    if (reportLinkEl) {
      reportLinkEl.setAttribute(
        'href',
        reportLinkEl.getAttribute('href') + ' ' + window.location.href,
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.error-block {
  font-size: map-get($font-size, 'base');
  text-align: center;

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: map-get($font-size, 'large');
  }
}
.error-block__number {
  font-size: 100px;
  font-weight: bold;
  color: #176fc1;
  line-height: 1;
  margin-top: -24px;
  letter-spacing: 2px;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    font-size: 150px;
  }

  @media (min-width: map-get($grid-breakpoints, 'md')) {
    font-size: 175px;
  }
}

.error-block__descr {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}
</style>
