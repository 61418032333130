<template>
  <div class="password-reset-form">
    <ng-fieldset :fields="formFields" v-model="formModel"></ng-fieldset>
    <ng-button
      class="mb-3"
      type="submit"
      color="primary"
      large
      @click.prevent="submit()"
      >{{ $t('send') }}</ng-button
    >
  </div>
</template>

<i18n>
  {
    "en": {
      "send": "Send instructions"
    },
    "ru": {
      "send": "Выслать инструкции"
    }
  }
</i18n>

<script>
import { validationMixin } from 'vuelidate';
import vuelidatable from '@ngservices_front/mixins/vuelidatable';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'PasswordResetForm',
  mixins: [validationMixin, vuelidatable],
  props: {
    formElId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formFields: null,
      formModel: null,
    };
  },
  validations: {
    formModel: {
      email: { required, email },
    },
  },
  computed: {
    formEl() {
      return document.getElementById(this.formElId);
    },
  },
  watch: {
    vuelidateErrors: {
      deep: true,
      handler() {
        this.setErrorsTo(this.formFields, { validationRoot: 'formModel' });
      },
    },
  },
  created() {
    this.formFields = window.passwordResetFormFields;
    this.formModel = window.passwordResetFormModel;
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.formEl.submit();
      } else {
        this.$vuetify.goTo(this.formEl, { offset: 100 });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
