import { get, set, has, uniqWith, isEqual } from 'lodash-es';
import messages from '../locales/validationMessages.json';

export default {
  computed: {
    _vuelidatedRootKey() {
      return Object.keys(this.$v.$params)[0];
    },
    _vuelidatedPaths(){
      const paths =  this.$v.$flattenParams().map(x => x.path);
      return uniqWith(paths, isEqual);
    },
    vuelidateErrors() {
      const result = {};
      this.$v.$flattenParams().forEach(x => {
        const rulePath = [...x.path, x.name];
        const ruleMessage = this.getRuleMessage(x.name, get(this.$v, [...x.path, '$params', x.name]));
        const isDirty = get(this.$v, [...x.path, '$dirty']);
        if (isDirty && !this.isVuelidateRuleMatched(rulePath)) {
          if (has(result, x.path)) {
            get(result, x.path).push(ruleMessage);
          } else {
            set(result, x.path, [ruleMessage]);
          }
          if (!has(result, x.path)) {
            set(result, x.path, []);
          }
        } else {
          if (!has(result, x.path)) {
            set(result, x.path, []);
          }
        }
      });
      return result;
    }
  },
  beforeCreate() {
    for (const locale in messages) this.$i18n.mergeLocaleMessage(locale, messages[locale]);
  },
  methods: {
    isVuelidateRuleMatched(path){
      return get(this.$v, path)
    },
    getRuleMessage(ruleName, ruleParams) {
      if (ruleName === 'minLength') {
        return this.$i18n.t(`validation.${ruleName}`, { n: ruleParams.min });
      }
      return this.$i18n.t(`validation.${ruleName}`);
    },
    setErrorsTo(targetObj, { validationRoot, errorField = 'errors' } ) {
      // mix vuelidateErrors to object with similar structure (usually formFields)
      const errorRoot = validationRoot ? this.vuelidateErrors[validationRoot] : this.vuelidateErrors;
      Object.keys(targetObj).forEach((key) => {
        if (errorRoot && errorRoot[key] && targetObj[key][errorField]) {
          targetObj[key][errorField] = errorRoot[key];
        }
      });
    }
  }
};