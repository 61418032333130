import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { VApp, VRow, VCol, VIcon, VAutocomplete } from 'vuetify/lib';

Vue.use(Vuetify, { components: { VApp, VRow, VCol, VIcon, VAutocomplete } });

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0070c5',
        accent: '#00b77e',
        secondary: '#e5eef7',
        info: '#2196f3',
        warning: '#ff9800',
        error: '#ff5252',
        success: '#4caf50',
        'success-dark': '#497D6D',
        grey: '#B3B9BE',
        'grey-dark': '#808A93',
        'grey-bg': '#F2F4F5',
      },
    },
    options: {
      customProperties: true,
    },
  },
  breakpoint: {
    mobileBreakpoint: 'sm',
  },
  // icons: {
  //   iconfont: 'mdi'
  // }
});
