<template>
  <entry-layout class="login-page">
    <a class="nolink mb-12" href="/"><app-logo /></a>
    <h1>{{ $t('signin.title') }}</h1>
    <div class="d-sm-none body-2">
      {{ $t('signin.noAccount') }}
      <a :href="urls.signup">{{ $t('signin.signup') }}</a>
    </div>
    <slot></slot>
    <div class="mt-10 body-2">{{ $t('signup.connectSocialNetworks') }}</div>
    <social-login :providers="providers" />
  </entry-layout>
</template>

<script>
import EntryLayout from '@ngservices_front/layouts/EntryLayout.vue';
import AppLogo from '@ngservices_front/components/AppLogo/AppLogo.vue';
import { getUrlByLocale } from '@ngservices_front/services/UrlService';
import SocialLogin from '../components/account/SocialLogin.vue';

export default {
  name: 'Login',
  components: { EntryLayout, AppLogo, SocialLogin },
  data() {
    return {
      providers: window.providers,
      urls: {
        signup: getUrlByLocale('signup', this.$i18n.locale),
      },
    };
  },
};
</script>
