import { get } from 'lodash-es';
import { notifyError } from '@/enhancers/useNotifications';

const errorHandlers = new Map();
const GENERAL_ERROR_RULE = { 'source.pointer': '' };

// stringify rules object to avoid object comparing issues
const stringifyRules = (rules) => {
  const rulesEntriesSoted = Object.entries(rules).sort(([key1], [key2]) => {
    return key1 - key2;
  });
  return JSON.stringify(rulesEntriesSoted);
};

const parseRules = (stringifiedRules) =>
  Object.fromEntries(JSON.parse(stringifiedRules));

const subscribeToError = (errorRules, cb) => {
  const stringifiedRules = stringifyRules(errorRules);
  const subscribers = errorHandlers.get(stringifiedRules) || [];
  errorHandlers.set(stringifiedRules, [...subscribers, cb]);
};

// subscribe to error, that caused because of certain data from request body
const subscribeToDataError = ({ dataKey, ...restRules }, cb) =>
  subscribeToError({ 'source.pointer': dataKey, ...restRules }, cb);

// subscribe to error, whose reason is general, not defined by certain data
const subscribeToGeneralError = ({ ...rules }, cb) =>
  subscribeToError({ ...GENERAL_ERROR_RULE, ...rules }, cb);

const unsubscribeToError = (errorRules, cb) => {
  const stringifiedRules = stringifyRules(errorRules);
  const subscribers = errorHandlers.get(stringifiedRules) || [];
  errorHandlers.set(
    stringifiedRules,
    subscribers.filter((handler) => handler !== cb),
  );
};

const unsubscribeToDataError = ({ dataKey, ...restRules }, cb) =>
  unsubscribeToError({ 'source.pointer': dataKey, ...restRules }, cb);

const unsubscribeToGeneralError = ({ ...rules }, cb) =>
  unsubscribeToError({ ...GENERAL_ERROR_RULE, ...rules }, cb);

const isMatchToRule = (obj, rule) => {
  let counter = 0;
  Object.entries(rule).forEach(([key, value]) => {
    if (get(obj, key) === value) counter += 1;
  });
  return counter === Object.keys(rule).length;
};

const getErrorHandlers = (error) => {
  const result = [];
  for (const rule of errorHandlers.keys()) {
    const ruleParsed = parseRules(rule);
    if (isMatchToRule(error, ruleParsed)) {
      result.push(...errorHandlers.get(rule));
    }
  }
  return result;
};

const fireError = (error, fireSettings = {}) => {
  const { exception = null } = fireSettings;

  if (!error.errors) throw error; // just throw unformatted error

  error.errors.forEach((e) => {
    if (!(exception && isMatchToRule(e, exception))) {
      const handlers = getErrorHandlers(e) || [];
      handlers.forEach((cb) => {
        cb(e);
      });
    }
  });
};

const getErrorDescription = (e) => e.detail;

const notifyErrorMessages = (error) => {
  notifyError(getErrorDescription(error));
};

const createSimpleError = (errorMessage) => ({
  errors: [
    {
      source: {
        pointer: '',
      },
      detail: errorMessage,
    },
  ],
});

export {
  subscribeToError,
  subscribeToDataError,
  subscribeToGeneralError,
  unsubscribeToError,
  unsubscribeToDataError,
  unsubscribeToGeneralError,
  fireError,
  getErrorDescription,
  notifyErrorMessages,
  createSimpleError,
};
