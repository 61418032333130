<template>
  <a
    class="app-menu-item"
    :class="[
      `app-menu-item--${view}`,
      {
        active: isActive,
        'with-icon': !!icon,
      },
    ]"
    :href="href"
  >
    <span class="app-menu-item__inner">
      <v-icon v-if="!!icon" class="app-menu-item__icon" :color="icon.color">
        {{ icon.text }}
      </v-icon>
      <slot></slot>
    </span>
  </a>
</template>

<script>
export default {
  name: 'AppMenuItem',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object,
    },
    href: {
      type: String,
    },
    view: {
      type: String,
      default: 'horizontal',
    },
  },
};
</script>

<style lang="scss" scoped>
.app-menu-item {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  color: $text-base;

  &:hover {
    color: var(--v-primary-base);
  }

  &.active {
    .app-menu-item__icon {
      color: var(--v-primary-base);
    }
  }

  &--horizontal {
    display: flex;
    align-items: center;
    padding: 0 12px;

    &::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: -1px;
      height: 3px;
      background: #0070c5;
      transition: max-width 0.4s;
      max-width: 0;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.24);
    }

    &.active {
      &::after {
        width: 100%;
        max-width: 200px;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  &--vertical {
    display: block;
    padding: 0;
    margin: 0 0 12px;

    &.active {
      .app-menu-item__inner::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.6em;
        width: 24px;
        height: 2px;
        background-color: var(--v-primary-base);
      }
    }

    &.active .app-menu-item__inner,
    .app-menu-item__inner:active,
    .app-menu-item__inner:focus {
      color: var(--v-primary-base);
      font-weight: 500;
    }
    &.with-icon {
      .app-menu-item__inner::before {
        display: none;
      }
    }

    .app-menu-item__inner {
      display: inline-block;
      padding-left: 36px;
      line-height: 1.4;
    }

    .app-menu-item__icon {
      position: absolute;
      left: 6px;
      top: -2px;
    }
  }
}
</style>
