<template>
  <v-dialog v-model="valueLocal" width="420">
    <ng-sheet style="position: relative">
      <ng-button
        small
        text
        icon
        color="grey-dark"
        icon-name="mdi-close"
        @click="close"
        style="position: absolute; top: 4px; right: 4px"
      ></ng-button>
      <h3 class="mt-0">{{ $t('subscription.dialogs.preRestoringTitle') }}</h3>
      <p v-html="$t('subscription.dialogs.weWillRenewSubscription', [priceString, expireAt])"></p>
      <div class="mt-6">
        <ng-button large class="mr-2" color="primary" @click="confirmDialog">{{
          $t('app.continue')
        }}</ng-button>
        <ng-button large text color="primary" @click="close">{{ $t('app.cancel') }}</ng-button>
      </div>
    </ng-sheet>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { getPriceString } from '@/utils/priceUtils';

export default {
  name: 'SubscriptionRestoreDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('subscription', ['price', 'currency', 'expireAt']),
    valueLocal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    priceString() {
      return getPriceString({ amount: this.price, currency: this.currency });
    },
  },
  methods: {
    close() {
      this.$emit('input', false);
      this.$emit('close');
    },
    confirmDialog() {
      this.$emit('confirmDialog');
    },
  },
};
</script>

<style lang="scss" scoped></style>
