var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{class:[
  'ng-layout',
  'ng-sidebar-layout',
  {
    'ng-sidebar-layout--fluid': _vm.fluid,
    'ng-layout--header-fixed': _vm.config.headerFixed || _vm.headerFixed,
  }
]},[_vm._t("banner"),_vm._t("header",function(){return [_c('app-header',{attrs:{"service-name":_vm.config.serviceName,"menu-items":_vm.config.topMenuItems,"fluid":_vm.fluid,"fixed":_vm.config.headerFixed || _vm.headerFixed,"custom-urls":_vm.config.customUrls},on:{"menu-icon-click":function($event){_vm.sidebarMenuShown = true}},scopedSlots:_vm._u([{key:"header-menu",fn:function(){return [_vm._t("header-menu")]},proxy:true},{key:"action",fn:function(){return [_vm._t("header-action")]},proxy:true}],null,true)})]}),_c('v-main',{staticClass:"main"},[_c('v-container',{class:[
        'main__container',
        _vm.contentClass,
        _vm.contentVCentered ? 'container--vcenter' : ''
      ],attrs:{"pa-0":"","fluid":_vm.fluid}},[_c('div',{staticClass:"ng-layout-sidebar"},[_c('v-navigation-drawer',{staticClass:"ng-layout-sidebar__sidebar app-sidebar",attrs:{"permanent":!_vm.$vuetify.breakpoint.mobile,"width":"200","height":"auto","floating":""},model:{value:(_vm.sidebarMenuShown),callback:function ($$v) {_vm.sidebarMenuShown=$$v},expression:"sidebarMenuShown"}},[_vm._t("sidebar",function(){return [_c('app-menu',{attrs:{"items":_vm.config.sidebarMenuItems,"active-item":_vm.currentPage && _vm.currentPage.id,"view":"vertical"}})]})],2),_c('div',{staticClass:"ng-layout-sidebar__content",class:{'ng-layout-sidebar__content--mobile': _vm.$vuetify.breakpoint.mobile}},[_c('div',{staticClass:"main__content"},[_c('v-container',{attrs:{"py-0":"","px-4":"","px-sm-6":"","px-md-8":"","fluid":!_vm.contentCentered}},[_vm._t("default")],2)],1)])],1)])],1),_vm._t("footer",function(){return [_c('app-footer',{attrs:{"dark":"","fluid":_vm.fluid,"view":_vm.config.footerView || 'small',"menu-items":_vm.config.bottomMenuItems}})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }