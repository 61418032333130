import {
  getUserWebgis,
  getNgwInstanceDomain,
  createWebgis,
  getWebgisServerLocations,
} from '@/api/webgisApi';

import { fireError } from '@ngservices_front/services/errorService';

const webgis = {
  namespaced: true,
  state: () => ({
    url: undefined,
    url_oauth: undefined,
    status: undefined,
    plan: undefined,
    ngwInstanceDomain: undefined,
    serverLocations: undefined,
  }),
  mutations: {
    SET_WEBGIS_URL(state, url) {
      state.url = url;
    },
    SET_WEBGIS_URL_OAUTH(state, url_oauth) {
      state.url_oauth = url_oauth;
    },
    SET_WEBGIS_STATUS(state, status) {
      state.status = status;
    },
    SET_WEBGIS_PLAN(state, plan) {
      state.plan = plan;
    },
    SET_NGW_INSTANCE_DOMAIN(state, ngwInstanceDomain) {
      state.ngwInstanceDomain = ngwInstanceDomain;
    },
    SET_SERVER_LOCATIONS(state, locations) {
      state.serverLocations = locations;
    },
  },
  actions: {
    async fetchWebgisData({ dispatch }) {
      try {
        const webgisData = await getUserWebgis({ useCase: 'get_webgis' });
        dispatch('updateWebgisData', webgisData);
      } catch (error) {
        fireError(error, {
          exception: { status: 404 },
        });
      }
    },
    updateWebgisData({ commit }, { url, url_oauth, state }) {
      commit('SET_WEBGIS_URL', url);
      commit('SET_WEBGIS_URL_OAUTH', url_oauth);
      commit('SET_WEBGIS_STATUS', state);
    },
    async fetchNgwInstanceDomain({ commit }) {
      try {
        const domain = await getNgwInstanceDomain();
        commit('SET_NGW_INSTANCE_DOMAIN', domain);
      } catch (error) {
        fireError(error);
      }
    },
    async fetchServerLocations({ commit }) {
      try {
        const locations = await getWebgisServerLocations();
        commit('SET_SERVER_LOCATIONS', locations);
      } catch (error) {
        fireError(error);
      }
    },
    async waitForWebgisStatus({ dispatch, state }, { targetStatus, maxTime, delay }) {
      return new Promise((resolve) => {
        let webgisData;

        const webgisStatusChecker = setInterval(async () => {
          webgisData = await getUserWebgis({ useCase: 'check_webgis_status' });
          dispatch('updateWebgisData', webgisData);

          if (state.status === targetStatus) {
            clearInterval(webgisStatusChecker);
            resolve(webgisData);
          }
        }, delay);

        const timer = setTimeout(() => {
          clearInterval(webgisStatusChecker);
          clearTimeout(timer);
          resolve(webgisData, { message: 'waiting is too long' });
        }, maxTime);
      });
    },
    createWebgisAndWaitForStatus({ commit, dispatch }, { webgisSettings }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          await createWebgis(webgisSettings, { useCase: 'create_webgis' });
          commit('SET_WEBGIS_STATUS', 'wait_for_create');
          try {
            const webgisData = await dispatch('waitForWebgisStatus', {
              targetStatus: 'started',
              delay: 1000,
              maxTime: 60000,
            });
            resolve(webgisData);
          } catch (error) {
            commit('SET_WEBGIS_STATUS', 'error');
            fireError(error);
            reject(error);
          }
        } catch (error) {
          commit('SET_WEBGIS_STATUS', 'error');
          fireError(error);
          reject(error);
        }
      });
    },
  },
};

export default webgis;
