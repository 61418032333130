<template>
  <div class="ng-checkbox">
    <v-checkbox v-model="$attrs.value" v-bind="innerAttrs" v-on="$listeners" @change="onChange()">
      <template v-slot:label v-if="innerAttrs.label">
        <span ref="label" v-html="$attrs.label"></span>
      </template>
    </v-checkbox>  
  </div>
</template>

<script>

export default {
  name: 'NgCheckbox',
  data() {
    return {
      defaultSettings: {
        hideDetails: 'auto'
      }
    }
  },
  computed: {
    innerAttrs() {
      return { 
        ...this.defaultSettings, 
        ...this.$attrs
      };
    }
  },
  mounted() {
    this._initInnerLinks();
  },
  methods: {
    _initInnerLinks(){
      const innerLinks = this.$refs.label.querySelectorAll('a');
      [...innerLinks].forEach((link) => {
        link.addEventListener('click', (e) => { e.stopPropagation(); })
      });
    },
    onChange() {
      this.$emit('input', this.$attrs.value);
    }
  }
};
</script>

<style lang="scss" scoped>
  .ng-checkbox{
    &::v-deep .v-input__slot{
      align-items: stretch;
    }

    &::v-deep .v-input--selection-controls{
      margin-top: 0;
    }

    &::v-deep  .error--text a{
      color: currentColor;
      border-bottom-color: currentColor;
    }

    &::v-deep label{
      margin-bottom: 0;
    }
  }
</style>