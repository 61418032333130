<template>
  <ngid-base-layout class="profile-page" fluid>
    <slot></slot>
    <v-row>
      <v-col cols="12" md="8">
        <profile-management />
      </v-col>
    </v-row>
  </ngid-base-layout>
</template>

<script>
import NgidBaseLayout from '@/layouts/NgidBaseLayout';
import ProfileManagement from '@/components/profile/ProfileManagement';

export default {
  name: 'Profile',
  components: { NgidBaseLayout, ProfileManagement },

  data() {
    return {};
  },
};
</script>

<style lang="scss">
.pic-upload {
  &.pic-upload--dropzone {
    .dropzone {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.4);
      font-size: 13px;
      color: #fff;
      padding: 10px;
      padding-top: 30px;
    }
  }
}
.pic-upload__inner {
  position: relative;

  &:hover {
    .pic-upload__delete {
      opacity: 1;
    }
  }
}

.pic-upload__edit,
.v-application .pic-upload__edit {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.4s;
  opacity: 1;
  z-index: 2;

  &:hover {
    text-decoration: none;
    color: #fff;
    opacity: 0.75;
  }

  &:active,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
}
.pic-upload__delete {
  right: -5px;
  position: absolute;
  top: -5px;
  background: var(--v-primary-base);
  font-size: map-get($font-size, 'base');
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.4s, transform 0.4s;
  border-radius: 50%;
  z-index: 3;
  backface-visibility: hidden;

  &:hover {
    transform: scale(1.1);
    background-color: #24749b;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: #fff;
  }
}

.dropzone {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border: 1px dashed #bdbdbd;
  opacity: 0;
  transition: opacity 0.4s;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 2px;
    border: 1px dashed #fff;
    opacity: 0.5;
  }
}

.profile-form__personal-photo-inner {
  width: 160px;
  height: 160px;
  border-radius: $border-radius-root;
  overflow: hidden;
}

/* Profile social */
.profile-social {
  margin-top: 60px;
}

.profile-social__list__item {
  margin-bottom: 18px;
  max-width: 500px;

  .square-icon {
    position: relative;
    vertical-align: middle;
    color: #fff;
    background-color: $dark-blue;
    border: 0;
    width: 36px;
    height: 36px;
    min-width: 36px;
    line-height: 36px;

    .square-icon__pic {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .btn {
    margin-left: -4px;
  }
}
</style>
