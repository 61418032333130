<template>
  <ngid-base-layout class="team-page" fluid>
    <ng-team />
    <slot></slot>
  </ngid-base-layout>
</template>

<script>
import NgidBaseLayout from '@/layouts/NgidBaseLayout';
import NgTeam from '@/components/team/NgTeam.vue';
export default {
  components: {
    NgidBaseLayout,
    NgTeam,
  },
};
</script>

<style lang="scss" scoped></style>
