<template>
  <label class="ng-label" :class="cssClasses">
    <slot>{{ text }}</slot>
  </label>
</template>

<script>
export default {
  name: 'NgLabel',
  props: {
    text: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssClasses() {
      return {
        'ng-label--error': this.error,
        'ng-label--required': this.required,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ng-label {
  display: inline-block;
  font-size: 14px;
  color: $gray-dark;

  &--error {
    color: var(--v-error-base);
  }

  &--required {
    &::after {
      content: ' *';
    }
  }
}
</style>
