<template>
  <v-list class="ng-list" v-bind="$attrs" v-on="$listeners">
    <v-list-item-group
      :value="value"
      @change="updateActiveItem"
      :mandatory="$attrs.mandatory"
    >
      <v-list-item
        v-for="(item, index) in items"
        class="ng-list__item"
        :key="index"
        :href="item.href"
        :value="item.value"
      >
        <v-list-item-icon v-if="item.iconPreppend">
          <v-icon>{{ item.iconPreppend }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title || item }}</v-list-item-title>
        <v-list-item-icon v-if="item.iconAppend">
          <v-icon>{{ item.iconAppend }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'NgList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
    },
  },
  methods: {
    updateActiveItem(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.ng-list {
  &__item:hover {
    color: var(--v-primary-base);
  }
}
</style>
