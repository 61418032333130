<template>
  <v-item-group class="ng-card-group" :value="value" @change="updateValue">
    <slot name="label">
      <ng-label
        class="mb-2"
        v-if="$attrs.label"
        :text="$attrs.label"
        :error="error"
        :required="$attrs.required"
      >
        {{ $attrs.label }}
      </ng-label>
    </slot>
    <template v-if="gridLayout">
      <v-row :dense="dense">
        <v-col
          v-for="item in items"
          :key="item[itemValue]"
          class="ng-card-group__col"
          :cols="cardCols && cardCols.default"
          :sm="cardCols && cardCols.sm"
          :md="cardCols && cardCols.md"
          :lg="cardCols && cardCols.lg"
          :xl="cardCols && cardCols.xl"
        >
          <v-item v-slot="{ active, toggle }" :value="item[itemValue]">
            <ng-sheet
              :color="active ? cardColorActive : cardColorComputed"
              @click="toggle"
              :outlined="outlined"
              :outlined-bg="active ? outlinedBgActive : outlinedBg"
              class="ng-card-group__item d-flex align-center cursor-pointer"
              :class="active ? 'ng-card-group__item--active' : ''"
              :height="cardHeight"
            >
              {{ item[itemText] }}
            </ng-sheet>
          </v-item>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <div class="d-sm-flex flex-wrap mb-n3">
        <v-item
          v-for="item in items"
          v-slot="{ active, toggle }"
          :key="item[itemValue]"
          :value="item[itemValue]"
        >
          <ng-sheet
            :color="active ? cardColorActive : cardColorComputed"
            @click="toggle"
            :outlined="outlined"
            :outlined-bg="active ? outlinedBgActive : outlinedBg"
            class="ng-card-group__item d-flex align-center cursor-pointer mr-3 mb-3"
            :class="active ? 'ng-card-group__item--active' : ''"
            :width="!$vuetify.breakpoint.xs ? cardWidth : ''"
            :height="cardHeight"
          >
            {{ item[itemText] }}
          </ng-sheet>
        </v-item>
      </div>
    </template>
    <div
      v-for="(error, index) in errorMessages"
      :key="index"
      class="error--text x-small mt-2"
    >
      {{ error }}
    </div>
    <slot name="hint">
      <div class="small text-muted mt-2" v-if="hint" v-html="hint"></div>
    </slot>
  </v-item-group>
</template>

<script>
export default {
  name: 'NgCardGroup',
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemText: {
      type: String,
      default: 'text',
    },
    hint: {
      type: String,
    },
    cardWidth: {
      type: [String, Number],
    },
    cardHeight: {
      type: [String, Number],
    },
    gridLayout: {
      type: Boolean,
      default: false,
    },
    cardCols: {
      type: Object,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    outlinedBg: {
      type: String,
    },
    outlinedBgActive: {
      type: String,
    },
    cardColor: {
      type: String,
    },
    cardColorActive: {
      type: String,
      default: 'primary',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    cardColorComputed() {
      const cardColorDefault = this.outlined ? 'white' : 'grey-bg';
      return this.active
        ? this.cardColorActive
        : this.cardColor || cardColorDefault;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.ng-card-group {
  &__item,
  .v-application &__item {
    height: 100%;
  }
  &::v-deep .row--dense {
    margin-left: -4px !important;
    margin-right: -4px !important;
  }
}
</style>
