<template>
  <v-sheet
    class="ng-sheet"
    :class="{
      ...sizeClasses,
      'ng-sheet--outlined':
        $attrs.outlined !== undefined && $attrs.outlined !== false,
      'ng-sheet--outlined-with-bg': !!outlinedBg,
    }"
    rounded
    v-bind="$attrs"
    v-on="$listeners"
    :style="cssStyles"
  >
    <slot></slot>
  </v-sheet>
</template>

<script>
export default {
  name: 'NgSheet',
  props: {
    xl: { type: Boolean },
    lg: { type: Boolean },
    sm: { type: Boolean },
    xs: { type: Boolean },
    bgSrc: { type: String },
    bgPosition: { type: String },
    bgSize: { type: String },
    outlinedBg: { type: String },
  },
  data() {
    return {};
  },
  computed: {
    sizeClasses() {
      return {
        'ng-size--xs': this.xs,
        'ng-size--sm': this.sm,
        'ng-size--default': !this.xs && !this.sm && !this.lg && !this.xl,
        'ng-size--lg': this.lg,
        'ng-size--xl': this.xl,
      };
    },
    cssStyles() {
      return {
        backgroundColor:
          this.$attrs.outlined !== undefined &&
          this.$attrs.outlined !== false &&
          this.outlinedBg &&
          `${this.outlinedBg} !important`,
        backgroundImage: this.bgSrc ? `url("${this.bgSrc}")` : 'none',
        backgroundPosition: this.bgPosition || 'center center',
        backgroundSize: this.bgSize || 'cover',
      };
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.ng-sheet {
  background-image: var(--bg-image);

  &.ng-sheet--outlined {
    border-color: $border-color !important;
    background-color: transparent !important;

    &.secondary {
      border-color: $ng-secondary !important;
    }
    &.primary {
      border-color: $primary !important;
    }
    &.accent {
      border-color: $accent !important;
    }
  }

  &.ng-size--xl {
    padding: 32px;
  }
  &.ng-size--lg {
    padding: 24px;
  }

  &.ng-size--default {
    padding: 16px;
  }

  &.ng-size--sm {
    padding: 12px;
  }
  &.ng-size--xs {
    padding: 8px;
  }
}
</style>
