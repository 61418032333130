<template>
  <v-menu
    transition="scale-transition"
    bottom
    offset-y
    max-width="290px"
    min-width="290px"
    v-bind="menuProps"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <ng-text-field
        class="ng-date-picker__input"
        :label="label"
        :placeholder="placeholder"
        :error="$attrs.error"
        :error-messages="$attrs['error-messages']"
        v-on="on"
        role="button"
        readonly
        clearable
        :value="valueFormatted"
        v-bind="textfieldProps"
        @click:clear="clearValue"
      >
        <template v-slot:append>
          <v-icon class="cursor-pointer" v-on="on">mdi-calendar</v-icon>
        </template>
      </ng-text-field>
    </template>
    <v-date-picker class="elevation-4"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="innerValue"
      color="primary"
      event-color="primary"
    ></v-date-picker>
  </v-menu>
</template>

<script>
  export default {
    name: 'NgDatePicker',
    props: {
      menuProps: {
        type: Object,
      },
      textfieldProps: {
        type: Object,
      },
      label: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      value: {
        type: [String, Array],
      },
      formatFunc: {
        type: Function,
      }
    },
    computed: {
      innerValue: {
        get() { return this.value },
        set(val) { this.$emit('input', val) },
      },
      valueFormatted() {
        return this.formatFunc ? this.formatFunc(this.innerValue) : this.innerValue;
      }
    },
    methods: {
      clearValue() {
        this.innerValue = '';
      }
    }
  }
</script>

<style lang="scss" scoped>
  .ng-date-picker{
    &__input:v--deep .v-input{
      cursor: pointer;
    }
  }
</style>