<template>
  <div class="banner">
    <picture>
      <source
        media="(min-width: 800px)"
        type="image/webp"
        :srcset="`${publicPath}img/banners/bf24_banner_2x_${bannerLocale}.webp`"
      />
      <source
        type="image/webp"
        :srcset="`${publicPath}img/banners/bf24_banner_s_2x_${bannerLocale}.webp`"
      />
      <source
        media="(min-width: 800px)"
        type="image/png"
        :srcset="`${publicPath}img/banners/bf24_banner_1x_${bannerLocale}.png 1x, ${publicPath}img/banners/bf24_banner_2x_${bannerLocale}.png 2x`"
      />
      <source
        type="image/png"
        :srcset="`${publicPath}img/banners/bf24_banner_s_1x_${bannerLocale}.png 1x, ${publicPath}img/banners/bf24_banner_s_2x_${bannerLocale}.png 2x`"
      />
      <img
        :src="`${publicPath}img/banners/bf24_banner_1x_${bannerLocale}.png`"
        alt="50% off for all datasets and subscriptions"
      />
    </picture>
    <a
      class="banner__link"
      :href="`${nextgisSiteUrl}/blog/black-friday24/?utm_source=nextgis-my&utm_medium=banner&utm_campaign=black-friday24&utm_content=${bannerLocale}`"
      target="_blank"
    ></a>
  </div>
</template>

<script>
import { getUrlByLocale } from '@ngservices_front/services/UrlService';

export default {
  name: 'TopBanner',
  data() {
    return {
      publicPath: '/static/',
    };
  },
  computed: {
    bannerLocale() {
      return this.$i18n.locale === 'ru' ? 'ru' : 'en';
    },
    nextgisSiteUrl() {
      return getUrlByLocale('nextgis_site', this.$i18n.locale);
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  background-color: #1c1c1c;
  height: $top-banner-height;

  img {
    width: 100%;
    max-width: 2560px;
    height: 100%;
    object-fit: cover;
    display: block;
    margin: 0 auto;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: all;
    height: 100%;
    z-index: 6;
  }
}
</style>
