export const getTimezone = () => {
  return {
    ianaName: Intl.DateTimeFormat().resolvedOptions().timeZone,
    offset: new Date().getTimezoneOffset(),
  };
};

export const getTimezoneStr = ({ withOffset = false } = {}) => {
  const { ianaName, offset } = getTimezone();
  const offsetInHours = -(offset / 60);
  const offsetStr =
    offset && withOffset
      ? ` (GMT${offsetInHours < 0 ? '' : '+'}${offsetInHours})`
      : '';
  return `${ianaName}${offsetStr}`;
};
