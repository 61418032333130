<template>
  <div class="locale-switcher">
    <v-menu top right>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="cursor-pointer">
          {{ currentItem.title }}
          <v-icon class="locale-switcher__icon">mdi-menu-down</v-icon>
        </span>
      </template>
      <ng-list :items="locales" v-model="currentLocale" mandatory> </ng-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'LocaleSwitcher',
  props: {
    routeParamToReplace: {
      type: String,
    },
    locales: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      menuItems: [
        {
          title: 'English',
          value: 'en',
        },
        {
          title: 'Русский',
          value: 'ru',
        },
      ],
    };
  },
  computed: {
    currentLocale: {
      get() {
        return this.$i18n.locale;
      },
      set(newLocale) {
        // this.$i18n.locale = newLocale;
        if (this.routeParamToReplace) {
          // TODO replace local in router only when project is moved to vue 3 to catch all texts changes (in settings, forms, etc)
          // this.$router.replace(
          //   { params: { [this.routeParamToReplace]: newLocale } },
          // );
          window.location.href = `${
            window.location.origin
          }/${newLocale}/${window.location.pathname.slice(4)}${
            window.location.search
          }`;
        }
      },
    },
    currentItem() {
      return this.locales.find((item) => item.value === this.currentLocale);
    },
  },
};
</script>
