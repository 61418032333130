<template>
  <dd
    :class="[
      'subscription-plan-feature',
      'plans__feature__item',
      { 'plans__feature__item--extra': feature.extra },
    ]"
    v-html="featureLabel"
  ></dd>
</template>

<script>
import { getUrlByLocale } from '@ngservices_front/services/UrlService';

export default {
  name: 'SubscriptionPlanFeature',
  props: {
    feature: {
      type: Object,
      required: true,
    },
  },
  computed: {
    featureLabel() {
      const { key, count, urlName, options } = this.feature;
      const featureOptions = {
        ...(options || {}),
        url: urlName && getUrlByLocale(urlName, this.$i18n.locale),
      };
      return this.feature.count
        ? this.$tc(key, count, featureOptions)
        : this.$t(key, featureOptions);
    },
  },
};
</script>

<style lang="scss" scoped></style>
