var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"app-menu-item",class:[
    `app-menu-item--${_vm.view}`,
    {
      active: _vm.isActive,
      'with-icon': !!_vm.icon,
    },
  ],attrs:{"href":_vm.href}},[_c('span',{staticClass:"app-menu-item__inner"},[(!!_vm.icon)?_c('v-icon',{staticClass:"app-menu-item__icon",attrs:{"color":_vm.icon.color}},[_vm._v(" "+_vm._s(_vm.icon.text)+" ")]):_vm._e(),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }