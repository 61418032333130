import { render, staticRenderFns } from "./Software.vue?vue&type=template&id=3893ca76&scoped=true"
import script from "./Software.vue?vue&type=script&lang=js"
export * from "./Software.vue?vue&type=script&lang=js"
import style0 from "./Software.vue?vue&type=style&index=0&id=3893ca76&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3893ca76",
  null
  
)

export default component.exports