<template>
  <v-app :class="['ng-layout', 'ng-entry-layout']">
    <v-main class="main auth-panel pa-0">
      <div class="auth-panel__content">
        <slot></slot>
      </div>
      <div class="auth-panel__promo">
        <div class="auth-panel__promo-bg"></div>
      </div>
    </v-main>
    <slot name="footer">
      <app-footer
        fluid
        :view="config.footerView || 'small'"
        :menu-items="config.bottomMenuItems"
      >
      </app-footer>
    </slot>
  </v-app>
</template>

<script>
import AppFooter from "../components/AppFooter/AppFooter.vue";
import { mapState } from "vuex";

export default {
  name: "EntryLayout",
  components: { AppFooter },
  computed: {
    ...mapState(["baseApp"]),
    config() {
      return this.baseApp.config;
    },
  },
};
</script>

<style lang="scss" scoped>
.ng-entry-layout {
  .main {
    min-height: 100%;
  }

  .footer {
    margin-top: -$footer-height;

    &::v-deep .footer__container-1 {
      background-color: transparent;
    }

    .footer-menu a {
      color: rgba(255, 255, 255, 0.64);
      border-bottom-color: rgba(255, 255, 255, 0.24);

      &:hover {
        color: rgb(255, 255, 255);
      }
    }
  }
  &::v-deep .logo {
    width: 120px;
    height: 16.33px;
  }
  &::v-deep .logo-pic {
    display: block !important;
    .logo-pic__item--dark {
      fill: #231f20;
    }
    .logo-pic__item--light {
      fill: #176fc1;
    }
  }

  &::v-deep .logo-pic--mini {
    display: none !important;
  }

  &::v-deep .free-consulting {
    margin-top: -10px;
    margin-bottom: 10px;
    transition: height 0.2s;

    &.active {
      @media (max-width: map-get($grid-breakpoints, "md")) {
        height: 96px;
        transition: 0.2s height 0.2s;

        .free-consulting__hint {
          display: block;
          margin-left: 48px;
          width: 220px;
          margin-top: 8px;
        }
      }

      .free-consulting__phone {
        left: -48px;
      }
    }
  }
}

.auth-panel__content {
  width: 100%;
  position: relative;
  padding: 40px;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  max-width: 550px;

  @media (min-width: map-get($grid-breakpoints, "md")) {
    float: left;
    width: 550px;
  }

  &::v-deep {
    .restore-pwd-link {
      margin-left: 10px;
      margin-bottom: 5px;
      margin-top: 2px;
    }

    .auth-panel__text {
      padding-bottom: 20px;
      margin-bottom: 20px;
      margin-top: 20px;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: 0;
      }

      @media (min-width: map-get($grid-breakpoints, "sm")) {
        padding-right: 100px;
      }
    }
    .auth-panel__text__icon {
      display: none;
      float: right;
      font-size: 89px;
      color: $primary;

      @media (min-width: map-get($grid-breakpoints, "sm")) {
        display: block;
        margin-right: -100px;
      }
    }

    .auth-panel__text--sm {
      @media (min-width: map-get($grid-breakpoints, "sm")) {
        padding-right: 60px;
      }

      .auth-panel__text__icon {
        font-size: 48px;

        @media (min-width: map-get($grid-breakpoints, "sm")) {
          margin-right: -60px;
        }
      }
    }
  }
}

.auth-panel__promo {
  height: 100vh;
  float: left;
  display: none;

  @media (min-width: map-get($grid-breakpoints, "md")) {
    padding-left: 550px;
    margin-left: -550px;
    width: 100%;
    display: block;
  }
}

.auth-panel__promo-bg {
  position: fixed;
  left: 550px;
  right: 0;
  height: 100vh;
  background: url("../assets/img/auth_bg.jpg") left top no-repeat #2661c2;
  background-size: cover;
}
.auth-panel__form {
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;

  &:first-child {
    margin-top: 0;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    border-bottom: 1px solid $border-color;
  }
}
</style>
