import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ru';

dayjs.extend(utc);

const DATE_FORMAT = {
  en: 'MMMM D, YYYY',
  ru: 'D MMMM YYYY',
};

const convertUtcToLocal = (utcDateString) => utcDateString && dayjs.utc(utcDateString).local();

const formatDate = (date, locale) =>
  date &&
  dayjs(date)
    .locale(locale)
    .format(DATE_FORMAT[locale] || DATE_FORMAT.en);

export { formatDate, convertUtcToLocal };
