<template>
  <ng-sheet class="subscription-info d-inline-block" color="#F2F4F5">
    <div class="mb-6">
      {{ $t('subscription.yourPlan') }}:
      <h3 class="text-capitalize d-inline-block mt-2 mb-0">
        {{ plan }}
      </h3>
      <div class="mt-1 small text-muted">
        <template v-if="autopayment">
          <div v-if="expireAt">{{ $t('subscription.nextPayment', [expireAt]) }}</div>
        </template>
        <template v-else>
          <div v-if="expireAt">
            {{ $t('subscription.until') }} <strong>{{ expireAt }}</strong
            >.
            <div v-if="canceledAt">
              {{ $t('subscription.afterCancelation') }}
            </div>
          </div>
        </template>
      </div>
    </div>

    <ng-button
      v-if="canceledAt"
      color="primary"
      block
      class="mb-2"
      @click="isRestoreDialogShown = true"
    >
      {{ $t('subscription.restore') }}
    </ng-button>
    <template v-else>
      <ng-button
        v-for="(action, index) in currentActions"
        :key="index"
        :href="action.link"
        v-bind="action.btnAttrs"
        block
        class="mb-2"
      >
        {{ action.text }}
      </ng-button>
    </template>

    <SubscriptionRestoreDialog
      @confirmDialog="restoreSubscription"
      v-model="isRestoreDialogShown"
    />
  </ng-sheet>
</template>

<script>
import i18n from '@/i18n';
import { mapState } from 'vuex';
import SubscriptionRestoreDialog from './SubscriptionRestoreDialog.vue';
import * as subscriptionService from '@/services/subscriptionService';
import { fireError } from '@ngservices_front/services/errorService';

const getLink = ({ linkKey, period }) => {
  const getQueryParamsForLink = (plan, period) =>
    period && plan ? `?plan=${plan}&period=${period}` : '';

  switch (linkKey) {
    case 'upgrade':
      return '/subscription/update';
    case 'change':
      return '/subscription/update';
    case 'cancel':
      return `/subscription/update${getQueryParamsForLink('free', 'monthly')}`;
    case 'buyMini':
      return `/subscription/update${getQueryParamsForLink('mini', period)}`;
    case 'buyPremium':
      return `/subscription/update${getQueryParamsForLink('premium', period)}`;
  }
};

const AUTOPAYMENT_ACTIONS = {
  free: [
    {
      text: i18n.t('subscription.upgrade'),
      linkKey: 'upgrade',
      btnAttrs: {
        color: 'primary',
      },
    },
  ],
  mini: [
    {
      text: i18n.t('subscription.upgrade'),
      linkKey: 'buyPremium',
      btnAttrs: {
        color: 'primary',
      },
    },
    {
      text: i18n.t('subscription.changePlan'),
      linkKey: 'change',
      btnAttrs: {
        outlined: true,
        color: 'primary',
      },
    },
    {
      text: i18n.t('subscription.cancelSubscription'),
      linkKey: 'cancel',
      btnAttrs: {
        outlined: true,
        color: 'primary',
      },
    },
  ],
  premium: [
    {
      text: i18n.t('subscription.changePlan'),
      linkKey: 'change',
      btnAttrs: {
        outlined: true,
        color: 'primary',
      },
    },
    {
      text: i18n.t('subscription.cancelSubscription'),
      linkKey: 'cancel',
      btnAttrs: {
        outlined: true,
        color: 'primary',
      },
    },
  ],
};

const NO_AUTOPAYMENT_ACTIONS_MINI = [...AUTOPAYMENT_ACTIONS.mini];
NO_AUTOPAYMENT_ACTIONS_MINI.splice(1, 0, {
  text: `${i18n.t('subscription.renew')} Mini`,
  linkKey: 'buyMini',
  btnAttrs: {
    color: 'primary',
    outlined: true,
  },
});

const NO_AUTOPAYMENT_ACTIONS = {
  ...AUTOPAYMENT_ACTIONS,
  mini: NO_AUTOPAYMENT_ACTIONS_MINI,
  premium: [
    {
      text: `${i18n.t('subscription.renew')} Premium`,
      linkKey: 'buyPremium',
      btnAttrs: {
        color: 'primary',
      },
    },
    ...AUTOPAYMENT_ACTIONS.premium,
  ],
};

export default {
  name: 'SubscriptionInfo',
  components: { SubscriptionRestoreDialog },
  data() {
    return {
      isRestoreDialogShown: false,
    };
  },
  computed: {
    ...mapState('subscription', [
      'plan',
      'price',
      'currency',
      'autopayment',
      'expireAt',
      'canceledAt',
      'interval',
    ]),
    currentActions() {
      if (this.canceledAt) return [];

      const actions = this.autopayment ? AUTOPAYMENT_ACTIONS : NO_AUTOPAYMENT_ACTIONS;
      return actions[this.plan.toLowerCase()].map((action) => ({
        ...action,
        link: getLink({
          linkKey: action.linkKey,
          period: this.period,
        }),
      }));
    },
  },
  methods: {
    async restoreSubscription() {
      try {
        await subscriptionService.restoreAndLoadSubscription();
        this.isRestoreDialogShown = false;
      } catch (e) {
        fireError(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-info {
  width: 100%;
  max-width: 380px;
}
</style>
