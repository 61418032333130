import axios from 'axios';
import httpClient, { responseErrorInterceptor } from './httpClient';
const END_POINT = '/autopayment/';
const { origin } = window.location;
import getCookie from '@/utils/getCookie';

const PAYMENT_OPTIONS = {
  yookassa: {
    payment_method_type: 'bank_card',
    return_url: `${origin}/subscription/?scenario=creation&payment-attempt-status=unknown`,
  },
  stripe: {
    success_url: `${origin}/subscription/?scenario=creation&payment-attempt-status=success`,
    cancel_url: `${origin}/subscription/?scenario=creation&payment-attempt-status=failed`,
  },
};

const createAutopaymentData = ({ provider, plan, interval, userData }) => ({
  provider,
  plan,
  interval,
  user_data: {
    first_name: userData.firstName,
    second_name: userData.lastName,
    email: userData.email,
    country: userData.country,
  },
  options: PAYMENT_OPTIONS,
});

const createAutopayment = ({ provider, plan, interval, userData }) =>
  httpClient
    .post(END_POINT, createAutopaymentData({ provider, plan, interval, userData }))
    .then((resp) => resp.data);

const updateAutopayment = ({ plan, interval, userData }) =>
  httpClient
    .patch(END_POINT, createAutopaymentData({ plan, interval, userData }))
    .then((resp) => resp.data);

const deleteAutopayment = () => httpClient.delete(END_POINT).then((resp) => resp.data);

const getAutopayment = () => httpClient.get(END_POINT).then((resp) => resp.data.autopayment);

const updateAutopaymentStatus = (value) =>
  httpClient.patch(`${END_POINT}status/`, { value }).then((resp) => resp.data);

const requestInvoice = ({
  selectedPlan,
  annualPayment,
  company_name,
  contact_person,
  contact_email,
  contact_phone,
  registration_number,
  tax_number,
  company_address,
  bank_details,
}) => {
  const axiosInstance = axios.create({
    baseURL: '/',
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-CSRFTOKEN': getCookie('ngid_csrftoken'),
    },
  });

  axiosInstance.interceptors.response.use((response) => response, responseErrorInterceptor);
  const postData = {
    'selected-plan': selectedPlan || '',
    'annual-payment': annualPayment || '',
    ngw_plans_period: annualPayment === 'on' ? 12 : 1,
    'customer-type': 'entity',
    payment_gateway: 'invoice',
    payment_type: 'invoice',
    'form_entity-company_name': company_name || '',
    'form_entity-contact_person': contact_person || '',
    'form_entity-contact_email': contact_email || '',
    'form_entity-contact_phone': contact_phone || '',
    'form_entity-registration_number': registration_number || '',
    'form_entity-tax_number': tax_number || '',
    'form_entity-company_address': company_address || '',
    'form_entity-bank_details': bank_details || '',
  };
  const formData = new FormData();
  Object.entries(postData).forEach(([key, value]) => formData.append(key, value));
  return axiosInstance.post('/subscription/update', formData);
};

export {
  createAutopayment,
  updateAutopayment,
  getAutopayment,
  deleteAutopayment,
  updateAutopaymentStatus,
  requestInvoice,
};
