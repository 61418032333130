<template>
  <v-btn
    class="ng-button"
    :class="cssClasses"
    :fab="icon"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon
      v-if="iconName && iconPosition !== 'right'"
      class="ng-button__icon"
      :class="{ 'ng-button__icon--left': iconPosition === 'left' }"
    >
      {{ iconName }}
    </v-icon>
    <slot></slot>
    <v-icon
      v-if="iconName && iconPosition === 'right'"
      class="ng-button__icon ng-button__icon--right"
    >
      {{ iconName }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'NgButton',
  props: {
    iconName: { type: String },
    iconPosition: { type: String },
    icon: { type: Boolean, default: false },
    lightweight: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
  },
  computed: {
    cssClasses() {
      return {
        'ng-button--default': !this.$attrs.color,
        'ng-button--icon': this.icon,
        'ng-button--lightweight': this.lightweight,
        'ng-button--bordered': this.bordered,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ng-button {
  font-family: $heading-font-family;

  &:not(.v-btn--outlined):not(.ng-button--bordered) {
    border-bottom: 0;
  }

  &--bordered {
    border: 1px solid currentColor;
  }

  &.v-size--x-large {
    padding-left: 32px;
    padding-right: 32px;
  }

  &.light {
    &.theme--light.v-btn--has-bg,
    &.v-btn--has-bg {
      background-color: white;
      color: var(--v-primary-base);

      &:hover,
      &:focus,
      &:active {
        &::before {
          opacity: 0;
        }
        color: $success;
      }
    }
  }

  &__icon {
    opacity: 0.8;

    &--left {
      margin-left: -4px;
      margin-right: 4px;
    }

    &--right {
      margin-left: 4px;
      margin-right: -4px;
    }
  }

  &--icon {
    border-radius: $border-radius-root;
  }

  &--lightweight {
    font-weight: 400;
  }
}

.theme--light.ng-button--default {
  color: var(--v-primary-base);
}

a.ng-button.v-btn--outlined {
  border-color: currentColor;

  &.theme--light.ng-button--default {
    color: $gray-dark;

    &:hover {
      color: $primary;
    }
  }
}
</style>
