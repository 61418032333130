import i18n from '@/i18n';
import { getReleases } from '@/api/repkaApi';
import { extendProductsWithReleases } from '@/utils/softwareUtils';
import { createSimpleError } from '@ngservices_front/services/errorService';

const getProductInstallers = async () => {
  const releases = await getReleases();
  if (releases && releases.length > 0) {
    return extendProductsWithReleases({ releases, lang: i18n.locale });
  } else {
    return Promise.reject(
      createSimpleError('Product installers list is empty'),
    );
  }
};

export default {
  getProductInstallers,
};
