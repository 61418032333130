<template>
  <v-app
    v-cloak
    :class="[
      'ng-layout',
      'ng-base-layout',
      {
        'ng-base-layout--fluid': fluid,
        'ng-layout--header-fixed': config.headerFixed || headerFixed,
      },
    ]"
  >
    <slot name="banner"></slot>
    <slot name="header">
      <app-header
        :service-name="config.serviceName"
        :menu-items="config.topMenuItems"
        :with-authorization="config.withAuthorization"
        @menu-icon-click="sidebarMenuShown = true"
        :fluid="fluid"
        :fixed="config.headerFixed || headerFixed"
        :custom-urls="config.customUrls"
      >
        <template v-slot:header-menu>
          <slot name="header-menu"> </slot>
        </template>
        <template v-slot:action>
          <slot name="header-action"> </slot>
        </template>
      </app-header>
    </slot>

    <v-main class="main">
      <v-container
        :class="[
          'main__container',
          contentClass,
          contentVCentered ? 'container--vcenter' : '',
        ]"
        pa-0
        :fluid="fluid"
      >
        <div class="layout-nosidebar">
          <div class="main__content">
            <template v-if="contentCentered">
              <v-container py-0>
                <slot></slot>
              </v-container>
            </template>
            <template v-else>
              <slot></slot>
            </template>
            <slot name="extra-content"></slot>
          </div>
        </div>
      </v-container>
    </v-main>

    <v-navigation-drawer
      class="app-sidebar"
      temporary
      fixed
      width="200"
      height="auto"
      v-model="sidebarMenuShown"
      floating
    >
      <!-- TODO move to AppSidebar.vue -->
      <slot name="sidebar">
        <app-menu
          :items="config.sidebarMenuItems"
          :active-item="currentPage && currentPage.id"
          view="vertical"
        >
        </app-menu>
      </slot>
    </v-navigation-drawer>

    <slot name="footer">
      <app-footer
        dark
        :fluid="fluid"
        centered
        :view="config.footerView || 'small'"
        :menu-items="config.bottomMenuItems"
        :email="config.email"
      >
      </app-footer>
    </slot>
  </v-app>
</template>

<script>
import AppHeader from '../components/AppHeader/AppHeader.vue';
import AppFooter from '../components/AppFooter/AppFooter.vue';
import AppMenu from '../components/AppMenu/AppMenu.vue';

import { mapState } from 'vuex';

export default {
  name: 'BaseLayout',
  components: {
    AppHeader,
    AppFooter,
    AppMenu,
  },
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: 'white',
    },
    contentCentered: {
      type: Boolean,
      default: false,
    },
    contentVCentered: {
      type: Boolean,
      default: false,
    },
    headerFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sidebarMenuShown: false,
    };
  },
  computed: {
    ...mapState('baseApp', ['config', 'currentPage']),
  },
};
</script>

<style lang="scss" scoped>
.ng-base-layout {
  &::v-deep .main {
    background-color: transparent;
  }
}

.layout-nosidebar {
  width: 100%;
}
</style>
