<template>
  <ng-text-field
    :type="isValueVisible ? 'text' : 'password'"
    :append-icon="isValueVisible ? 'mdi-eye-off' : 'mdi-eye'"
    @click:append="toggleValueVisibility"
    v-bind="$attrs"
    v-on="$listeners"
  ></ng-text-field>
</template>

<script>
export default {
  name: 'NgPasswordTextField',
  data() {
    return {
      isValueVisible: false,
    };
  },
  methods: {
    toggleValueVisibility() {
      this.isValueVisible = !this.isValueVisible;
    },
  },
};
</script>

<style lang="scss" scoped></style>
