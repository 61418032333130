<template>
  <div class="social-login">
    <ul class="social-login-list list-inline">
      <li
        v-for="provider in providersSorted"
        :key="provider.name"
        class="social-login-list__item mb-2"
      >
        <google-signin-button
          v-if="provider.name === 'google'"
          :href="provider.url"
          class="mr-3"
          @click="onButtonClick($event, provider.name)"
        />
        <v-hover v-else v-slot="{ hover }">
          <a :href="provider.url">
            <social-icon
              :name="provider.name"
              :elevation="hover ? 1 : 0"
              class="mr-1"
              @click="onButtonClick($event, provider.name)"
            ></social-icon
          ></a>
        </v-hover>
      </li>
    </ul>
  </div>
</template>

<script>
import SocialIcon from '../ui/SocialIcon.vue';
import GoogleSigninButton from '../ui/GoogleSigninButton.vue';

export default {
  components: { SocialIcon, GoogleSigninButton },
  props: {
    providers: {
      type: Array,
    },
  },
  computed: {
    providersSorted() {
      const googleProvider = this.providers.find(
        (item) => item.name === 'google',
      );
      return googleProvider
        ? [
            googleProvider,
            ...this.providers.filter((item) => item.name !== 'google'),
          ]
        : this.providers;
    },
  },
  methods: {
    onButtonClick(e, providerName) {
      this.$emit('buttonClick', providerName);
    },
  },
};
</script>

<style lang="scss" scoped>
.social-login-list {
  margin-top: 10px;

  & > .social-login-list__item {
    position: relative;
    display: inline-block;
    padding-left: 2px;
    padding-right: 2px;
  }
}
</style>
