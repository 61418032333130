<template>
  <NgInput class="ng-autocomplete" v-bind="$attrs">
    <template #default="{ inputAttrs }">
      <v-autocomplete v-bind="inputAttrs" v-on="$listeners">
        <template v-for="(_, slot) of $slots" v-slot:[slot]>
          <slot :name="slot"></slot>
        </template>
      </v-autocomplete>
    </template>
  </NgInput>
</template>

<script>
import NgInput from './NgInput.vue';

export default {
  name: 'NgAutocomplete',
  components: {
    NgInput,
  },
};
</script>

<style lang="scss" scoped></style>
