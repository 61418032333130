import i18n from '@/i18n';
import { formatDate, convertUtcToLocal } from '@/utils/dateUtils';

const subscription = {
  namespaced: true,
  state: () => ({
    availablePlans: undefined,
    yearlySubscriptionDiscount: 0,
    plan: undefined,
    autopayment: undefined,
    interval: undefined,
    price: undefined,
    currency: undefined,
    expireAt: undefined,
    canceledAt: undefined,
    isDataLoaded: false,
    subscriptionAlertType: null,
  }),
  getters: {
    availablePlansMap(state) {
      return (
        state.availablePlans &&
        state.currency &&
        state.availablePlans.reduce((acc, plan) => {
          return {
            ...acc,
            [plan.id]: {
              ...plan,
              monthlyPrice: {
                amount: plan.price_original.monthly[state.currency],
                currency: state.currency,
              },
              yearlyPrice: {
                amount: plan.price_original.yearly[state.currency],
                currency: state.currency,
              },
              monthlyPriceDiscounted: plan.price_discounted && {
                amount: plan.price_discounted.monthly[state.currency],
                currency: state.currency,
              },
              yearlyPriceDiscounted: plan.price_discounted && {
                amount: plan.price_discounted.yearly[state.currency],
                currency: state.currency,
              },
            },
          };
        }, {})
      );
    },
  },
  mutations: {
    SET_SUBSCRIPTION(state, subscription) {
      const { plan, interval, price, currency, expire_at, canceled_at, autopayment } = subscription;

      state.plan = plan;
      state.interval = interval;
      state.price = parseInt(price);
      state.currency = currency.toLowerCase();
      state.expireAt = formatDate(convertUtcToLocal(expire_at), i18n.locale);
      state.canceledAt = formatDate(convertUtcToLocal(canceled_at), i18n.locale);
      state.autopayment = autopayment;
    },
    SET_AVAILABLE_PLANS(state, plans) {
      state.availablePlans = plans;
    },
    SET_YEARLY_SUBSCRIPTION_DISCOUNT(state, discount) {
      state.yearlySubscriptionDiscount = discount;
    },
    SET_IS_DATA_LOADED(state, isDataLoaded) {
      state.isDataLoaded = isDataLoaded;
    },
    SET_SUBSCRIPTION_ALERT_TYPE(state, alertType) {
      state.subscriptionAlertType = alertType;
    },
  },
};

export default subscription;
