import { helpers } from 'vuelidate/lib/validators';

const username = helpers.regex('username', /^[a-zA-Z0-9-_.]+$/);

const domain = helpers.regex(
  'domain',
  /^(?![0-9-])[a-zA-Z0-9-]{3,63}[a-zA-Z0-9]$/,
);

export { username, domain };
