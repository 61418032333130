<template>
  <v-dialog v-model="modelValueLocal" width="420" persistent>
    <ng-sheet class="webgis-success-modal">
      <ng-button
        class="webgis-success-modal__close"
        small
        text
        icon
        color="grey-dark"
        icon-name="mdi-close"
        @click="modelValueLocal = false"
      ></ng-button>
      <div class="text-center mb-6">
        <img src="../../assets/img/webgis_started.png" width="240" height="240" />
        <h3 class="mt-0">{{ $t('webgis.isReady') }}</h3>
        <p>
          {{ $t('subscription.yourPlan') }} - <span class="text-capitalize">{{ plan }}</span
          >. <br />
          {{ descriptions[plan] }}
        </p>
      </div>
      <ng-button
        v-for="(action, index) in currentActions"
        :href="action.link"
        :key="index"
        v-bind="action.btnAttrs"
        block
        class="mb-2"
      >
        {{ action.text }}
      </ng-button>
    </ng-sheet>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/i18n';

const CONSULTATION_BTN = {
  text: i18n.t('webgis.consultation'),
  link: '#',
  btnAttrs: {
    outlined: true,
    color: 'accent',
    iconName: 'mdi-headset',
    onclick:
      'ym(86035883,"reachGoal","onboarding-webgis"); Chatra(\'openChat\', true); return false;',
  },
};

export default {
  name: 'WebgisSuccessModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descriptions: {
        free: this.$t('subscription.getMoreWithMiniPremium'),
        mini: this.$t('subscription.getMoreWithPremium'),
        premium: this.$t('app.thanksForChoosingNextgis'),
      },
    };
  },
  computed: {
    ...mapState('subscription', ['plan']),
    ...mapState('webgis', ['url']),
    modelValueLocal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    actions() {
      return {
        free: [
          {
            text: this.$t('subscription.upgrade'),
            link: '/subscription/update',
            btnAttrs: {
              color: 'primary',
            },
          },
          {
            text: this.$t('webgis.goToWebgis'),
            link: this.url,
            btnAttrs: {
              outlined: true,
              target: '_blank',
            },
          },
          CONSULTATION_BTN,
        ],
        mini: [
          {
            text: this.$t('subscription.upgrade'),
            link: '/subscription/update',
            btnAttrs: {
              color: 'primary',
            },
          },
          {
            text: this.$t('webgis.goToWebgis'),
            link: this.url,
            btnAttrs: {
              outlined: true,
              target: '_blank',
            },
          },
          CONSULTATION_BTN,
        ],
        premium: [
          {
            text: this.$t('webgis.goToWebgis'),
            link: this.url,
            btnAttrs: {
              color: 'primary',
              target: '_blank',
            },
          },
          CONSULTATION_BTN,
        ],
      };
    },
    currentActions() {
      return this.actions[this.plan];
    },
  },
};
</script>

<style lang="scss" scoped>
.webgis-success-modal {
  position: relative;

  &__close {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}
</style>
