<template>
  <ng-sheet
    class="social-icon"
    :color="colors[name]"
    :title="name"
    xs
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon color="white"> {{ `mdi-${name}` }}</v-icon>
  </ng-sheet>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return ['google', 'facebook', 'twitter'].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      colors: {
        google: '#fff',
        facebook: '#3b5999',
        twitter: '#55acee',
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>
