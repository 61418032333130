<template>
  <NgInput class="ng-text-field" v-bind="$attrs">
    <template #default="{ inputAttrs }">
      <v-text-field v-bind="inputAttrs" v-on="$listeners">
        <template v-for="(_, slot) of $slots" v-slot:[slot]>
          <slot :name="slot"></slot>
        </template>
      </v-text-field>
    </template>
  </NgInput>
</template>

<script>
import NgInput from './NgInput.vue';

export default {
  name: 'NgTextField',
  components: {
    NgInput,
  },
  inheritAttrs: false,
};
</script>

<style lang="scss">
.v-text-field {
  &.v-text-field--enclosed .v-text-field__details {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 4px !important;
  }
  .theme--light {
    &:not(.v-input--has-state):hover .v-input__control > .v-input__slot:before {
      border-color: $gray;
    }
  }
}
</style>

<style lang="scss" scoped>
.ng-text-field {
  &::v-deep .v-input {
    &__append,
    &__append-outer,
    &__prepend,
    &__prepend-outer {
      align-self: center;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}
</style>
