<template>
  <entry-layout class="signup-page">
    <a class="nolink mb-12" href="/"><app-logo /></a>
    <h1>{{ $t('signup.title') }}</h1>
    <div class="d-sm-none body-2">
      {{ $t('signup.haveAccount') }}.
      <a :href="urls.login">{{ $t('signin.buttonText') }}</a>
    </div>
    <slot></slot>
    <div class="mt-10 body-2">{{ $t('signup.connectSocialNetworks') }}</div>
    <social-login :providers="providersNoUrl" @buttonClick="showConfirmation" />
  </entry-layout>
</template>

<script>
import EntryLayout from '@ngservices_front/layouts/EntryLayout.vue';
import AppLogo from '@ngservices_front/components/AppLogo/AppLogo.vue';
import { getUrlByLocale } from '@ngservices_front/services/UrlService';
import { confirmDialog } from '@nextgis/vuetify-notice';
import SocialLogin from '../components/account/SocialLogin.vue';

export default {
  name: 'Signup',
  components: { EntryLayout, AppLogo, SocialLogin },
  data() {
    return {
      providers: window.providers,
    };
  },
  computed: {
    providersNoUrl() {
      return this.providers.map((x) => ({ name: x.name }));
    },
    urls() {
      return {
        login: getUrlByLocale('login', this.$i18n.locale),
        terms: `${getUrlByLocale('nextgis_terms', this.$i18n.locale)}`,
        privacy: `${getUrlByLocale('nextgis_privacy', this.$i18n.locale)}`,
      };
    },
    confirmDialogOptions() {
      return {
        content: `
            <h3 class="mt-2 mb-3 grey--text text--darken-4">${this.$t(
              'signup.confirmRulesDialog.title',
            )}</h3>
            <div class="small">${this.$t('signup.confirmRulesDialog.content', {
              termsUrl: this.urls.terms,
              privacyUrl: this.urls.privacy,
            })}</div>
          `,
        props: {
          buttonTrueText: this.$t('app.agree'),
          buttonFalseText: this.$t('app.disagree'),
        },
      };
    },
  },
  methods: {
    showConfirmation(providerName) {
      confirmDialog(
        this.confirmDialogOptions.content,
        this.confirmDialogOptions.props,
      ).then((answer) => {
        if (answer) {
          const provider = this.providers.find((x) => x.name === providerName);
          if (provider && provider.url) window.location = provider.url;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
