import { render, staticRenderFns } from "./NgidOnpremise.vue?vue&type=template&id=7380c012&scoped=true"
import script from "./NgidOnpremise.vue?vue&type=script&lang=js"
export * from "./NgidOnpremise.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7380c012",
  null
  
)

export default component.exports