<template>
  <ngid-base-layout class="ngid-onpremise-page" fluid>
    <h1>NextGIS ID on-premise</h1>
    <div class="text-center" v-if="!isSubscriptionDataLoaded">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" md="8" order="2" order-md="1">
          <template v-if="plan === 'premium'">
            <div v-html="$t('onpremise.description')"></div>
            <slot></slot>
          </template>
          <template v-else>
            <ng-sheet color="light-secondary" v-html="$t('onpremise.onpremiseIsNotAvailable')">
            </ng-sheet>
          </template>
        </v-col>
      </v-row>
    </div>
  </ngid-base-layout>
</template>

<script>
import { mapState } from 'vuex';
import NgidBaseLayout from '@/layouts/NgidBaseLayout';

export default {
  name: 'NgidOnpremise',
  components: {
    NgidBaseLayout,
  },
  computed: {
    ...mapState('subscription', { plan: 'plan', isSubscriptionDataLoaded: 'isDataLoaded' }),
  },
};
</script>

<style lang="scss" scoped></style>
