import httpClient from './httpClient';
const WEBGIS_ENDPOINT = 'v1/webgis/';

const getNgwInstanceDomain = () =>
  httpClient.get(`${WEBGIS_ENDPOINT}refs/`).then((resp) => resp.data.domain);

const createWebgis = (webgisSettings, config = {}) =>
  httpClient.post(WEBGIS_ENDPOINT, webgisSettings, config);

const getUserWebgis = (config = {}) => {
  return httpClient.get(`${WEBGIS_ENDPOINT}my/`, config).then((resp) => resp.data);
};

const getWebgisServerLocations = () =>
  httpClient.get(`${WEBGIS_ENDPOINT}cluster/locations/`).then((resp) => resp.data);

export { getNgwInstanceDomain, createWebgis, getUserWebgis, getWebgisServerLocations };
