/* eslint-disable */
import svg4everybody from 'svg4everybody';

import 'material-design-icons-iconfont/dist/material-design-icons.css';

export default function ({ locale }) {
  // Detect Ie
  (function () {
    var ie = 0;
    try {
      ie = navigator.userAgent.match(/(MSIE |Trident.*rv[ :])([0-9]+)/)[2];
    } catch (e) {}
    if (ie !== 0)
      document.getElementsByTagName('html')[0].className += ' ie v' + ie;
  })();

  // Forms module
  var Forms = (function () {
    return {
      init: function () {
        [...document.querySelectorAll('.form-control')].forEach(
          (formControl) => {
            formControl.addEventListener('keyup', () => {
              formControl
                .closest('.form-group.has-error')
                .classList.remove('has-error');
              [
                ...formControl.parentElement.el.getElementsByClassName(
                  'has-error',
                ),
              ].forEach((errorMessage) => errorMessage.remove());
            });
          },
        );
      },
    };
  })();

  const scrollTo = (target) => {
    const targetEl = document.querySelector(target);
    if (targetEl) {
      window.scrollTo({
        top:
          targetEl.getBoundingClientRect().top -
          document.querySelector('.header').getBoundingClientRect().height,
        behavior: 'smooth',
      });
    }
  };

  document.addEventListener('DOMContentLoaded', function () {
    svg4everybody();
    Forms.init();

    [...document.querySelectorAll('.scrollto-link')].forEach((scrollToLink) => {
      scrollToLink.addEventListener('click', function (e) {
        e.preventDefault();
        var target = scrollToLink.getAttribute('href');
        scrollTo(target);
      });
    });

    const messageCloseButtons = document.querySelectorAll(
      '[data-dismiss="alert"]',
    );
    [...messageCloseButtons].forEach((closeButton) => {
      closeButton.addEventListener('click', () => {
        closeButton.parentElement.remove();
      });
    });

    const timeoutMessages = document.querySelectorAll('.alert--timeout');
    [...timeoutMessages].forEach((message) => {
      setTimeout(() => {
        message.remove();
      }, 3000);
    });
  });
}
