<template>
  <v-menu class="user-menu" v-bind="$attrs" v-on="$listeners">
    <ng-list :items="menuItems" dense></ng-list>
  </v-menu>
</template>

<i18n>
  {
    "en": {
      "profile": "Profile",
      "logout": "Sign out"
    },
    "ru": {
      "profile": "Профиль",
      "logout": "Выйти"
    }
  }
</i18n>

<script>
  import NgList from '../ui/NgList/NgList.vue';
  import { getUrlByLocale } from '../../services/UrlService';

  export default {
    name: 'UserMenu',
    components: { NgList },
    props: {
      logoutUrl: {
        type: String,
      },
      profileUrl: {
        type: String,
      }
    },
    data() {
      return {
        menuItems: [
          {
            title: this.$t('profile'), 
            href:  this.profileUrl || getUrlByLocale('ngid_profile', this.$i18n.locale),
          },
          {
            title: this.$t('logout'), 
            href: this.logoutUrl || getUrlByLocale('logout', this.$i18n.locale),
            iconAppend: 'mdi-exit-to-app'
          },
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>