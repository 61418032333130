<template>
  <div class="login-form">
    <ng-fieldset :fields="formFields" v-model="formModel"></ng-fieldset>
    <div class="login-form__actions d-sm-flex align-center">
      <div>
        <ng-button
          class="mr-1"
          ref="submitButton"
          type="submit"
          color="primary"
          large
          @click.prevent="submit()"
        >
          {{ $t('signin') }} </ng-button
        ><span class="d-none d-sm-inline">
          {{ $t('or') }}&nbsp;<a class="d-inline-block" href="/signup/">{{
            $t('signup')
          }}</a></span
        >
      </div>
      <v-spacer></v-spacer>
      <a class="login-form__forget-link" href="/password/reset/">{{
        $t('forget')
      }}</a>
    </div>
  </div>
</template>

<i18n>
  {
    "en": {
      "signin": "Sign in",
      "signup": "Sign up",
      "forget": "Forgot password?",
      "or": "or"
    },
    "ru": {
      "signin": "Войти",
      "signup": "Создать аккаунт",
      "forget": "Забыли пароль?",
      "or": "или"
    }
  }
</i18n>

<script>
import { validationMixin } from 'vuelidate';
import vuelidatable from '@ngservices_front/mixins/vuelidatable';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'LoginForm',
  mixins: [validationMixin, vuelidatable],
  props: {
    formElId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formFields: null,
      formModel: null,
    };
  },
  validations: {
    formModel: {
      login: { required },
      password: { required },
    },
  },
  computed: {
    formEl() {
      return document.getElementById(this.formElId);
    },
  },
  watch: {
    vuelidateErrors: {
      deep: true,
      handler() {
        this.setErrorsTo(this.formFields, { validationRoot: 'formModel' });
      },
    },
  },
  created() {
    this.formFields = window.loginFormFields;
    this.formModel = window.loginFormModel;
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.formEl.submit();
      } else {
        this.$vuetify.goTo(this.formEl, { offset: 100 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  &__actions {
    font-size: 14px;
  }

  &__forget-link {
    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      margin-top: 12px;
      display: inline-block;
    }
  }
}
</style>
