import httpClient from '@/api/httpClient';
const TEAM_API_URL = '/api/v1/team/';

const getTeam = () => httpClient.get(TEAM_API_URL).then((resp) => resp.data);

const updateUsers = (newUsers) =>
  httpClient.patch(TEAM_API_URL, { users: newUsers }).then((resp) => resp.data);

const searchUser = (userNameQuery) =>
  httpClient.get(`/api/v1/users/?username=${userNameQuery}`).then((resp) => resp.data);

export { getTeam, updateUsers, searchUser };
