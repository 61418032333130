<template>
  <ngid-base-layout class="webgis-create" fluid>
    <h1>{{ $t('webgis.create') }}</h1>
    <v-row>
      <v-col cols="12" lg="7">
        <p class="webgis-create__description">
          <span v-html="`${$t('webgis.description')}&nbsp;`"></span>
          <span
            class="d-lg-none"
            v-html="$t('webgis.useWithQgis', { url: connectUrl })"
          ></span>
        </p>

        <template v-if="formFields.location_id.options.length">
          <!-- <p v-html="$t('webgis.chooseDomain')"></p> -->
          <ng-form-vuelidatable
            class="webgis-create__form"
            :fields="formFields"
            v-model="formModel"
            :validation-rules="validationRules"
            :btn-text="$t('webgis.create')"
            :btnAttrs="{
              color: 'primary',
              large: true,
              class: 'mt-4',
            }"
            use-case="create_webgis"
            :loading="isFormSubmitting"
            @submit="submitForm"
          />
        </template>
        <div class="text-center" v-else>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
      <v-col cols="5" class="d-none d-lg-block">
        <WebgisRelatedInfo is-compact />
      </v-col>
    </v-row>
    <slot></slot>
    <v-dialog v-model="isModalShown" width="360" persistent>
      <ng-sheet>
        <h3 v-html="$t('webgis.isBeingCreatedPopup')"></h3>
        <p v-html="$t('webgis.isBeingCreatedWaitPopup')"></p>
        <div class="text-center">
          <v-progress-circular
            class="mt-6 mb-10"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </ng-sheet>
    </v-dialog>
  </ngid-base-layout>
</template>

<script>
import store from '@/store';
import NgidBaseLayout from '@/layouts/NgidBaseLayout';
import NgFormVuelidatable from '@ngservices_front/components/ui/NgFormVuelidatable';
import WebgisRelatedInfo from '@/components/webgis/WebgisRelatedInfo.vue';
import { required } from 'vuelidate/lib/validators';
import { domain } from '@ngservices_front/js/validators';
import { getUrlByLocale } from '@ngservices_front/services/UrlService';

import { mapActions, mapState } from 'vuex';

export default {
  name: 'WebgisCreate',
  components: {
    NgidBaseLayout,
    NgFormVuelidatable,
    WebgisRelatedInfo,
  },
  data() {
    return {
      formFields: {
        subdomain_name: {
          name: 'subdomain_name',
          //label: 'Web GIS domain name :',
          label: this.$t('webgis.chooseDomain'),
          placeholder: 'youraddress',
          widget: 'text',
          errors: [],
          serverErrors: '',
          attrs: {
            class: 'webgis-create__subdomain-name',
          },
        },
        // language: {
        //   name: 'ngw_language',
        //   label: this.$t('app.language'),
        //   widget: 'select',
        //   options: [
        //     { value: 'en', text: 'English' },
        //     { value: 'ru', text: 'Русский' },
        //   ],
        //   errors: [],
        //   serverErrors: '',
        // },
        location_id: {
          name: 'ngw_server_location',
          label: this.$t('webgis.location'),
          widget: 'cardGroup',
          options: [], // get by API
          attrs: {
            dense: true,
            hint: this.$t('webgis.locationHint'),
            cardWidth: 150,
            outlined: true,
            outlinedBgActive: this.$vuetify.theme.currentTheme.secondary,
            itemValue: 'id',
            itemText: 'display_name',
          },
          errors: [],
          serverErrors: '',
        },
      },
      formModel: {
        subdomain_name: undefined,
        // language: this.$i18n.locale,
        location_id: undefined,
      },
      validationRules: {
        subdomain_name: { required, domain },
        location_id: { required },
      },
      isFormSubmitting: false,
    };
  },
  computed: {
    ...mapState('webgis', ['status', 'ngwInstanceDomain', 'serverLocations']),
    ...mapState('baseUser', ['username']),
    isModalShown() {
      return this.status === 'wait_for_create' || this.status === 'started';
    },
    connectUrl() {
      return getUrlByLocale('nextgis_connect_docs', this.$i18n.locale);
    },
  },
  watch: {
    ngwInstanceDomain: {
      handler(val) {
        if (val) {
          this.$set(this.formFields.subdomain_name.attrs, 'suffix', `.${val}`);
        }
      },
      once: true,
    },
    serverLocations: {
      handler(val) {
        if (val) {
          this.$set(this.formFields.location_id, 'options', val);
        }
      },
      once: true,
    },
    username: {
      handler(username) {
        const subdomainName =
          username && username.replace(/[_.]/g, '-').replace(/^([0-9-]+)/, '');
        this.formModel = {
          ...this.formModel,
          subdomain_name: subdomainName,
        };
      },
      once: true,
    },
  },
  beforeCreate() {
    store.dispatch('webgis/fetchNgwInstanceDomain');
    store.dispatch('webgis/fetchServerLocations');
  },
  methods: {
    ...mapActions({
      createWebgis: 'webgis/createWebgisAndWaitForStatus',
    }),

    async submitForm() {
      this.isFormSubmitting = true;
      try {
        const webgisData = await this.createWebgis({
          webgisSettings: {
            subdomain_name:
              this.formModel.subdomain_name &&
              this.formModel.subdomain_name.toLowerCase(),
            language: this.$i18n.locale,
            location_id: this.formModel.location_id,
          },
        });
        this.isFormSubmitting = false;
        window.location.href = `/webgis/?webgis-status=${webgisData.state}`;
      } catch (e) {
        this.isFormSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.webgis-create {
  &::v-deep .webgis-create__subdomain-name {
    max-width: 475px;
  }
}
</style>
