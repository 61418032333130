import Vue from 'vue';
import NgButton from './components/ui/NgButton/NgButton';
import NgSheet from './components/ui/NgSheet/NgSheet';
import NgPrice from './components/ui/NgPrice/NgPrice';
import NgTabs from './components/ui/NgTabs/NgTabs';
import NgList from './components/ui/NgList/NgList';
import SelfLink from './components/ui/SelfLink/SelfLink';

Vue.component('ng-button', NgButton);
Vue.component('ng-sheet', NgSheet);
Vue.component('ng-price', NgPrice);
Vue.component('ng-tabs', NgTabs);
Vue.component('ng-list', NgList);
Vue.component('self-link', SelfLink);