var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ng-price",class:{ 'ng-price--simple': _vm.simple, 'ng-price--crossed': _vm.isCrossed }},[(_vm.currencyComp.position === 'first')?[_c('span',{class:[
        'ng-price__currency',
        `ng-price__currency--${_vm.currency.toLowerCase()}`,
        _vm.currencyClass,
      ],domProps:{"innerHTML":_vm._s(_vm.currencyComp.symbol)}}),_c('span',{class:['ng-price__amount', _vm.amountClass]},[_vm._v(_vm._s(_vm.amountFormatted))]),(_vm.monthly || _vm.yearly)?_c('span',{class:['ng-price__suffix', _vm.suffixClass]},[_vm._t("suffix",function(){return [_vm._v(_vm._s(_vm.suffix)+" ")]})],2):_vm._e()]:_vm._e(),(_vm.currencyComp.position === 'last')?[_c('span',{class:['ng-price__amount', _vm.amountClass]},[_vm._v(_vm._s(_vm.amountFormatted))]),_vm._v(" "),_c('span',{class:[
        'ng-price__currency',
        `ng-price__currency--${_vm.currency.toLowerCase()}`,
        _vm.currencyClass,
      ],domProps:{"innerHTML":_vm._s(_vm.currencyComp.symbol)}}),(_vm.monthly || _vm.yearly)?_c('span',{class:['ng-price__suffix', _vm.suffixClass]},[_vm._t("suffix",function(){return [_vm._v(_vm._s(_vm.suffix)+" ")]})],2):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }