<template>
  <span v-if="isLocked">
    <v-tooltip v-if="tooltip" right>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-if="isLocked"
          class="text-muted lockable-link lockable-link--locked"
          v-bind="attrs"
          v-on="on"
        >
          <slot></slot>
          <v-icon class="lockable-link__icon text-muted" x-small>
            mdi-lock
          </v-icon>
        </span>
      </template>
      {{ tooltip }}
    </v-tooltip>
    <span v-else class="text-muted lockable-link lockable-link--locked">
      <slot></slot>
      <v-icon class="lockable-link__icon text-muted" x-small>mdi-lock</v-icon>
    </span>
  </span>
  <a class="lockable-link" v-else-if="href" :href="href"><slot></slot></a>
</template>

<script>
export default {
  props: {
    isLocked: {
      type: Boolean,
      required: true,
    },
    href: {
      type: String,
    },
    tooltip: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.lockable-link {

  &--locked {
    cursor: not-allowed;
  }

  &__icon {
    position: relative;
    top: -1px;
    opacity: 0.75;
    margin-right: 2px;
  }
}
</style>
