<template>
  <v-app v-cloak :class="[
    'ng-layout',
    'ng-sidebar-layout',
    {
      'ng-sidebar-layout--fluid': fluid,
      'ng-layout--header-fixed': config.headerFixed || headerFixed,
    }
  ]">
    <slot name="banner"></slot>
    <slot name="header">
      <app-header
        :service-name = "config.serviceName"
        :menu-items = "config.topMenuItems"
        @menu-icon-click = "sidebarMenuShown = true"
        :fluid = "fluid"
        :fixed = "config.headerFixed || headerFixed"
        :custom-urls="config.customUrls">
        <template v-slot:header-menu>
          <slot name="header-menu">
          </slot>
        </template>
        <template v-slot:action>
          <slot name="header-action">
          </slot>
        </template>
      </app-header>
    </slot>

    <v-main class="main">
      <v-container 
        :class="[
          'main__container',
          contentClass,
          contentVCentered ? 'container--vcenter' : ''
        ]"
        pa-0
        :fluid="fluid">
        <div class="ng-layout-sidebar">
          <v-navigation-drawer
            :permanent="!$vuetify.breakpoint.mobile"
            class="ng-layout-sidebar__sidebar app-sidebar"
            width="200" height="auto"
            v-model="sidebarMenuShown" floating
          > <!-- TODO move to AppSidebar.vue -->
            <slot name="sidebar">
              <app-menu
                :items="config.sidebarMenuItems"
                :active-item="currentPage && currentPage.id"
                view="vertical">
              </app-menu>
            </slot>
          </v-navigation-drawer>
          <div class="ng-layout-sidebar__content" 
            :class="{'ng-layout-sidebar__content--mobile': $vuetify.breakpoint.mobile}"
          >
            <div class="main__content">
              <v-container py-0 px-4 px-sm-6 px-md-8 :fluid="!contentCentered">
                <slot></slot>
              </v-container>
            </div>
          </div>
        </div>
      </v-container>
    </v-main>

    <slot name="footer">
      <app-footer dark :fluid="fluid"
        :view="config.footerView || 'small'"
        :menu-items="config.bottomMenuItems">
      </app-footer>
    </slot>
  </v-app>
</template>

<script>
import AppHeader from '../components/AppHeader/AppHeader.vue';
import AppFooter from '../components/AppFooter/AppFooter.vue';
import AppMenu from '../components/AppMenu/AppMenu.vue';

import { mapState } from 'vuex';

export default {

  name: 'SidebarLayout',
  components: {
    AppHeader,
    AppFooter,
    AppMenu
  },
  props: {
    fluid: {
      type: Boolean,
      default: false
    },
    contentBg: {
      type: String,
      default: 'white'
    },
    contentClass: {
      type: String,
      default: 'white'
    },
    contentCentered:{
      type: Boolean,
      default: false
    },
    contentVCentered:{
      type: Boolean,
      default: false
    },
    headerFixed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      sidebarMenuShown: false
    };
  },
  watch: {
    isMobile(val) {
      if (val) {
        this.sidebarMenuShown = false;
      }
    }
  },
  computed:{
    ...mapState('baseApp', ['config', 'currentPage']),
    isMobile(){
      return this.$vuetify.breakpoint.mobile
    },
  }
};
</script>

<style lang="scss" scoped>
  .ng-sidebar-layout{
    &::v-deep .main{
      background-color: transparent;
    }
  }

  .ng-layout-sidebar{
    width: 100%;

    &__content{
      margin-left: 200px;

      &--mobile{
        margin-left: 0;
      }
    }
  }
</style>
